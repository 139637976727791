import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

const Component = ({ time, className, format }) => {
    return (
        <time className={ className }>{ time && moment(time).isValid() ? moment(time).format(format) : '--' }</time>
    );
};

Component.propTypes = {
    time: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.instanceOf(moment)
    ]),
    className: PropTypes.string,
    format: PropTypes.string,
};

Component.defaultProps = {
    time: '',
    className: '',
    format: I18n.t('datetime_format.long'),
};

export default Component;
