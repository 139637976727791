import { createFleetApiInstanceWithToken } from '../util';

const ticket = {
    fetchTicketList: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/tickets', { params });
    },

    fetchOneTicket: id => {
        return createFleetApiInstanceWithToken().get(`/tickets/${ id }`);
    },

    createTicket: (payload = {}) => {
        return createFleetApiInstanceWithToken().post('/tickets', payload);
    },

    updateOneTicket: (id, payload = {}) => {
        return createFleetApiInstanceWithToken().patch(`/tickets/${ id }`, payload);
    },

    updateManyTickets: (payload = {}) => {
        return createFleetApiInstanceWithToken().patch('/tickets', payload);
    },

    fetchJournal: id => {
        return createFleetApiInstanceWithToken().get(`/tickets/${ id }/journals`);
    },

    fetchAttatchmentUrl: ext => {
        return createFleetApiInstanceWithToken().get(`/tickets/attachment_upload_url?file_ext=${ ext }`);
    },

    fetchMyTask: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/tickets/self', { params });
    },

    fetchAssignment: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/tickets/assignment', { params });
    },

    assignSfTicket: scooterId => {
        return createFleetApiInstanceWithToken().post(`/scooters/${ scooterId }/swap_f_ticket`, {
            scooter_id: scooterId,
        });
    },
};

export default ticket;
