import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Modal from 'components/Modal';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import FlexGroup from 'components/FlexGroup';
import {
    toggleErrorDialog,
    updateFleetAdminUser,
    updateManyFleetAdminUsers,
} from 'actions';
import { TYPE_SELECT, TYPE_TEXT } from 'components/Form/Input/input-type';
import AuthFeature from 'components/AuthFeature';
import { AUTH_VIEW_FLEET_USER_ROLE } from 'constants/permission';
import api from 'api';
import './update-user.scss';


class UpdateUser extends React.Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
        onClose: PropTypes.func.isRequired,
        onUpdated: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            singleUser: undefined,
            userRoles: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.singleUser === undefined) {
            const { selectedIdList, users } = props;
            const single = selectedIdList.length === 1;
            let singleUser = {};
            if (single) {
                singleUser = users.filter(user => user.id === selectedIdList[0])[0];

                return { singleUser };
            }

            return { singleUser };
        }

        return null;
    }

    componentDidMount() {
        this.fetchUserRolesById();
    }

    componentDidUpdate(prevProps, prevState) {
        const { singleUser, userRoles } = this.state;
        if (this.single && singleUser.franchisee_id !== prevState.singleUser.franchisee_id) {
            this.fetchUserRolesById(singleUser.franchisee_id);
        }

        // clean role_id if userRoles changed and userRoles not include current role_id
        if (this.single &&
            userRoles !== prevState.userRoles &&
            !userRoles.map(role => role.id).includes(singleUser.role_id)
        ) {
            this.setState({
                singleUser: {
                    ...singleUser,
                    role_id: ''
                },
            });
        }
    }

    get single() {
        const { selectedIdList } = this.props;

        return selectedIdList.length === 1;
    }

    handleChange = e => {
        if (!this.single) {
            return;
        }

        const { name, value } = e.target;
        this.setState(state => {
            return {
                singleUser: {
                    ...state.singleUser,
                    [name]: value,
                }
            };
        });
    }

    handleSubmit = async e => {
        const formData = serialize(e.currentTarget, { hash: true });
        const needUpdate = Object.keys(formData).length > 0;

        if (!needUpdate) {
            return;
        }

        const { dispatch, selectedIdList, onUpdated } = this.props;
        const single = this.single;

        try {
            let error;
            if (single) {
                error = (await dispatch(updateFleetAdminUser(selectedIdList[0], formData))).error;
            }

            if (!single) {
                formData.ids = selectedIdList;
                error = (await dispatch(updateManyFleetAdminUsers(formData))).error;
            }

            if (error) {
                throw error;
            }

            onUpdated();
        }
        catch (error) {
            const { code } = error.response.data;
            const message = code !== undefined ?
                I18n.t(`admin.update_user_error_message_${ code }`) :
                I18n.t('general_error_message');
            dispatch(toggleErrorDialog(message));
        }
    }

    fetchUserRolesById(franchisee_id) {
        api.fetchUserRoles({ franchisee_id: [franchisee_id] })()
            .then(({ data: userRoles }) => {
                this.setState({ userRoles });
            });
    }

    render() {
        const { selectedIdList, onClose } = this.props;
        const { singleUser, userRoles } = this.state;
        const single = this.single;
        const empty = selectedIdList.length === 0;
        const title = single ? I18n.t('admin.update_user') : I18n.t('admin.update_users');
        const roleOptions = userRoles.map(role => ({
            name: role.name,
            value: role.id,
        }));
        roleOptions.unshift({ name: '', value: '' });

        return (
            <Modal className="update-user" title={ title } onClose={ onClose }>
                { empty && <h1>No User has been selected</h1> }
                { !empty && (
                    <Form onSubmit={ this.handleSubmit } onChange={ this.handleChange }>
                        { single && (
                            <Input
                                name="account"
                                type={ TYPE_TEXT }
                                caption="admin.account"
                                value={ singleUser.account }
                                viewMode
                            />
                        ) }
                        { single && (
                            <Input
                                name="name"
                                type={ TYPE_TEXT }
                                caption="admin.name"
                                value={ singleUser.name }
                                required
                            />
                        ) }
                        <AuthFeature requiredList={ [ AUTH_VIEW_FLEET_USER_ROLE ] }>
                            <Input
                                name="role_id"
                                type={ TYPE_SELECT }
                                caption="admin.role"
                                selected={ singleUser.role_id }
                                value={ roleOptions }
                                required
                            />
                        </AuthFeature>
                        <FlexGroup end gap>
                            <Button type="button" className="btn-cancel" onClick={ onClose } outline>
                                <Translate value="cancel" />
                            </Button>
                            <Button color="primary" type="submit">
                                <Translate value="submit" />
                            </Button>
                        </FlexGroup>
                    </Form>
                ) }
            </Modal>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(UpdateUser);
