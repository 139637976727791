import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Filter from 'components/Filter';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import { I18n } from 'react-redux-i18n';
import { TYPE_NUMBER } from 'components/Form/Input/input-type';
import { FormGroup } from 'reactstrap';

import './filter.scss';

class NumberRangeFilter extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        max: PropTypes.number,
        min: PropTypes.number,
        onApply: PropTypes.func,
        applied: PropTypes.bool,
    }

    static defaultProps = {
        title: '',
        max: undefined,
        min: undefined,
        onApply: () => {},
        applied: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            errorMessage: undefined,
            inputFrom: undefined,
            inputTo: undefined,
        };

        this.toRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        // when prev applied = true and then false. reset values
        const { applied } = this.props;

        if (applied === false && prevProps.applied === true) {
            this.setState({
                inputFrom: undefined,
                inputTo: undefined,
            });
        }
    }

    handleFilterReset = () => {
        const { onApply } = this.props;

        this.setState({
            open: false,
            inputFrom: undefined,
            inputTo: undefined,
        });

        onApply({
            from: undefined,
            to: undefined,
        });
    }

    handleFilterApply = () => {
        const { onApply } = this.props;
        const { inputFrom, inputTo } = this.state;

        this.setState({
            open: false,
        });

        onApply({
            from: Number(inputFrom),
            to: Number(inputTo)
        });
    }

    handleFilterToggle = () => {
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    }

    handleFilterClickOutside = () => {
        this.setState({ open: false });
    }

    handleChange = field => e => {
        e.preventDefault();

        const { min, max } = this.props;
        const { value } = e.target;
        let updateNumber = value !== '' ? Number(value) : undefined;
        updateNumber = Number.isNaN(updateNumber) ? undefined : updateNumber;

        const updatedState = {
            ...this.state,
            [field]: updateNumber,
        };
        const { inputFrom, inputTo } = updatedState;

        if (min > updateNumber) {
            updatedState.errorMessage = I18n.t('validation_error.rangeUnderflow');
        }
        else if (max < updateNumber) {
            updatedState.errorMessage = I18n.t('validation_error.rangeOverflow');
        }
        else if (typeof inputTo !== 'number' || typeof inputFrom !== 'number') {
            updatedState.errorMessage = I18n.t('validation_error.require_both_numbers');
        }
        else if (inputTo < inputFrom) {
            updatedState.errorMessage = I18n.t('validation_error.wrong_order_of_numbers');
        }
        else {
            updatedState.errorMessage = '';
        }

        this.setState(updatedState);
    }

    getTitle = () => {
        const { errorMessage, inputFrom, inputTo } = this.state;
        const { applied, title } = this.props;

        let displayTitlle = title;

        if (
            !errorMessage &&
            applied &&
            typeof inputFrom === 'number' &&
            typeof inputTo === 'number'
        ) {
            displayTitlle = `${ title }: ${ inputFrom } -  ${ inputTo }`;
        }

        return displayTitlle;
    }

    render() {
        const { applied, min, max } = this.props;
        const { open, errorMessage, inputFrom, inputTo } = this.state;

        const inputClass = classnames({
            'form-control': true,
            'max-props': !(max && min),
        });

        return (
            <Filter
                className="number-range-filter"
                value={ this.getTitle() }
                open={ open }
                applied={ applied }
                errorMessage={ errorMessage }
                onToggle={ this.handleFilterToggle }
                onReset={ this.handleFilterReset }
                onApply={ this.handleFilterApply }
                onClickOutside={ this.handleFilterClickOutside }
            >
                <Form inline tag="div">
                    <FormGroup>
                        <Input
                            caption="from"
                            className={ inputClass }
                            type={ TYPE_NUMBER }
                            value={ inputFrom }
                            min={ min }
                            max={ max }
                            onChange={ this.handleChange('inputFrom') }
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            caption="to"
                            className={ inputClass }
                            type={ TYPE_NUMBER }
                            value={ inputTo }
                            min={ min }
                            max={ max }
                            onChange={ this.handleChange('inputTo') }
                            required
                        />
                    </FormGroup>
                </Form>
            </Filter>
        );
    }
}
export default NumberRangeFilter;
