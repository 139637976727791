import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Smart from 'components/Smart';
import AuthView from 'components/AuthView';
import ScooterProfile from 'components/Scooter/Profile';
import NavigationBar from 'components/NavigationBar';
import { Refresh } from 'components/Pagination';
import {
    fetchSingleScooter,
    clearSingleScooter,
    toogleNavigation,
} from 'actions';
import './scooter-profile.scss';

class PopupScooterDetail extends Component {
    static propTypes = {
        singleScooter: ImmutablePropTypes.map,
    };

    static defaultProps = {
        singleScooter: new Map(),
    }

    constructor(props) {
        super(props);

        this.queryPayload = {
            scooter_id: undefined,
            page: 1,
            size: 20,
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
    }

    fetchData = () => {
        const { dispatch, match } = this.props;
        const scooterId = match.params.scooterId;

        return dispatch(fetchSingleScooter(scooterId)).then(data => {
            if (data.error?.response.status === 403) {
                dispatch(clearSingleScooter());
                dispatch(toogleNavigation());
            }
        });
    }

    render() {
        const { singleScooter } = this.props;
        const children = (
            singleScooter ?
                (
                    <Smart
                        className="scooter-profile-page"
                        fetch={ this.fetchData }
                        pauseRefresh
                        seamless
                    >
                        <NavigationBar sticky title={ singleScooter.get('plate') }>
                            <Refresh onClick={ this.fetchData } time={ singleScooter.get('__responseTime') } />
                        </NavigationBar>
                        <ScooterProfile item={ singleScooter.toJS() } />
                    </Smart>
                ) :
                ''
        );

        return (
            <AuthView>
                { children }
            </AuthView>
        );
    }
}

export default connect((state, ownProps) => ({
    singleScooter: state.scooter.get('singleScooter')[ownProps.match.params.scooterId],
    i18n: state.i18n,
}))(PopupScooterDetail);
