import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import AuthView from 'components/AuthView';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import {
    fetchScooterList, updateDocumentTitle, clearSingleScooter,
} from 'actions';
import FilterBar, {
    FILTER_SERVICE,
    FILTER_RENTAL_STATE,
    FILTER_BATTERY,
    FILTER_TRACKER_SOC,
    FILTER_MAIN_TYPE,
    FILTER_SUB_TYPE,
    FILTER_MODEL,
    FILTER_CORPORATE
} from './FilterBar';
import List from './List';
import './scooter-list.scss';



class ScooterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIdList: [],
            queryPayload: {
                plate: undefined,
                filter: {
                    [FILTER_SERVICE]: undefined,
                    [FILTER_RENTAL_STATE]: undefined,
                    [FILTER_BATTERY]: undefined,
                    [FILTER_TRACKER_SOC]: undefined,
                    [FILTER_MAIN_TYPE]: undefined,
                    [FILTER_SUB_TYPE]: undefined,
                    [FILTER_MODEL]: undefined,
                    [FILTER_CORPORATE]: undefined,
                },
                sort: {},
                page: 1,
                size: getDefaultPageSize().value,
            },
        };
        this.pauseRefresh = false;

    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(I18n.t(`routes.${ pathname }`)));
    }

    componentDidUpdate() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(I18n.t(`routes.${ pathname }`)));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
    }

    handleSubmit = value => {
        this.setState((prevState) => ({
            selectedIdList: [],
            queryPayload: {
                ...prevState.queryPayload,
                filter: {},
                plate: value === '' ? undefined : value,
                page: 1,
                sort: {},
            }
        }), this.fetchScooterList);

    }

    fetchScooterList = () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...params } = queryPayload;

        return dispatch(fetchScooterList({
            ...filter,
            ...params,
        }));
    }

    handleFilterOn = (type, value) => {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;

        switch (type) {
        case FILTER_MODEL:
        case FILTER_SERVICE:
        case FILTER_RENTAL_STATE:
        case FILTER_MAIN_TYPE:
        case FILTER_SUB_TYPE:
        case FILTER_CORPORATE:
            filter[type] = value.inputSelect;
            break;
        case FILTER_BATTERY:
        case FILTER_TRACKER_SOC:
            filter[`${type}_from`] = value.from;
            filter[`${type}_to`] = value.to;
            break;
        default:
        };

        // rebind
        this.setState((prevState) => ({
            selectedIdList: [],
            queryPayload: {
                ...prevState.queryPayload,
                filter,
                page: 1,
            },
        }), this.fetchScooterList);

    };

    handleChange = type => value => {
        const { queryPayload } = this.state;
        queryPayload.page = 1;
        queryPayload[type] = value;

        this.setState({
            selectedIdList: [],
            queryPayload: {
                ...queryPayload,
            }
        }, this.fetchScooterList);
    }

    handleSelect = list => {
        this.setState({
            selectedIdList: list,
        });
    }

    render() {
        const { selectedIdList, queryPayload } = this.state;

        return (
            <AuthView className="scooter-list">
                <FilterBar
                    defaultSettings={ queryPayload }
                    onChange={ this.handleFilterOn }
                    onSubmit={ this.handleSubmit }
                />
                <List
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchScooterList }
                    onSelect={ this.handleSelect }
                    onChange={ this.handleChange }
                    selectedIdList={ selectedIdList }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(ScooterList);
