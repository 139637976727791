import {
    TRUNK_CLOSED,
    TRUNK_OPEN,
    TRUNK_LOCKED,

    SCOOTER_STATE_UNLOCK,
    SCOOTER_STATE_ENGINE_ON,
    SCOOTER_STATE_LOCK,
    SCOOTER_STATE_SHUTDOWN,

    SCOOTER_OFFLINE,
    SCOOTER_ONLINE,

    FORCE_RETURN_ERROR_CODE_3000,
    FORCE_RETURN_ERROR_CODE_3006,
    FORCE_RETURN_ERROR_CODE_3007,
    FORCE_RETURN_ERROR_CODE_3008,
    FORCE_RETURN_ERROR_CODE_3009,
    FORCE_RETURN_ERROR_CODE_3010,

} from 'constants/scooter';

const trunkState = {
    [TRUNK_CLOSED]: 'Closed',
    [TRUNK_OPEN]: 'Open',
    [TRUNK_LOCKED]: '--',
};
const scooterState = {
    [SCOOTER_STATE_UNLOCK]: 'Unlocked',
    [SCOOTER_STATE_ENGINE_ON]: 'Motor On',
    [SCOOTER_STATE_LOCK]: 'Locked',
    [SCOOTER_STATE_SHUTDOWN]: 'Shutdown',
};
const availabilityState = {
    [SCOOTER_OFFLINE]: 'Offline',
    [SCOOTER_ONLINE]: 'Online',
};

const scooter = {
    'details': 'Details',
    'remote_control': 'Remote Control',
    'lock': 'Lock',
    'unlock': 'Unlock',
    'open_trunk': 'Open Trunk',
    'find': 'Find',
    'plate': 'Plate No.',
    'battery_with_unit': 'SOC (%)',
    'battery': 'SOC',
    'tracker_soc_with_unit': 'GTU (%)',
    'tracker_soc': 'GTU',
    'remaining_mileage_with_unit': 'Remaining (km)',
    'remaining_mileage': 'Remaining',
    'csq': 'CSQ',
    'satellites': 'Satellites',
    'model': 'Model',
    'activity': 'Activity',
    'rental_state': 'State',
    'last_rental_end_time': 'Last Rental Time',
    'idle': 'Idle (hrs)',
    'total_mileage': 'ODO',
    'total_mileage_with_unit': 'ODO (km)',
    'scooter_state': 'Scooter State',
    'location': 'Location',
    'trunk_state': 'Trunk',
    'last_connection_date': 'Last Connection Time',
    'lct': 'Last Connection Time (hrs)',
    'last_udpated': 'Last Updated',
    'service': 'Service',
    'activation': {
        'on': 'Online',
        'off': 'Offline',
    },
    'trunk_state_map': trunkState,
    'scooter_state_map': scooterState,
    'availability_state_map': availabilityState,
    'batch_update_service_state_error_message': 'Oops! Scooter(s) (%{plates}) Failed to Set Service State.',
    'force_return': 'Force Return',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3000}_error_message`]: 'The Rental is not found',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3006}_error_message`]:
        'Battery state is invalid',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3007}_error_message`]: 'Trunk is not closed (trunk open or unlock)',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3008}_error_message`]: 'Motor is on',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3009}_error_message`]: 'Scooter is not lock',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3010}_error_message`]: 'GTU connection error',
    'force_return_error_message': 'Force Return failed (%{code})',
    'number_constraint_of_scooter': 'Choose no more than 200 scooters.',
    'excellent': 'Excellent',
    'good': 'Good',
    'ok': 'OK',
    'marginal': 'Marginal',
    'poor': 'Poor',
    'no_signal_detectable': 'No Signal',
    'satellitesHigh': 'High accuracy',
    'satellitesLow': 'Low accuracy',
    'corporate': 'Corporate',
};

export default scooter;
