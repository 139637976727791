import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BatteryCapacity from 'components/BatteryCapacity';
import Plate from 'components/Scooter/Plate';
import RentalState from 'components/Scooter/RentalState';
import { convertDistance } from 'helpers/util';
import { Translate } from 'react-redux-i18n';
import Cell from 'components/ListView/Cell';
import moment from 'moment';
import {
    BATTERY_ERROR_204,
    BATTERY_ERROR_238
} from 'constants/scooter';

class ScooterListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            scooter_id: PropTypes.string,
            city: PropTypes.number,
            model: PropTypes.string,
            is_activate: PropTypes.bool,
            plate: PropTypes.string,
            soc: PropTypes.number,
            rental_state: PropTypes.number,
            last_rental_end_time: PropTypes.string,
            last_connection_date: PropTypes.string,
            total_mileage: PropTypes.number,
            tag: PropTypes.number,
            tag_description: PropTypes.string,
            tag_assignee: PropTypes.string,
            tracker_soc: PropTypes.number,
        }).isRequired,
    }

    getDuration = (now, time) => {
        const duration = Math.floor(now.diff(time, 'hours', true) * 10) / 10;
        return Math.max(duration, 0);
    }

    renderField({ data, name, width }) {
        let el;
        const value = typeof data[name] === 'string' && data[name].trim() === ''
            ? ' ' : data[name];
        const now = moment();

        switch (name) {
        case 'model_display_name':
            el = (value === ' ' || value === null) ? '--' : value;
            break;
        case 'plate':
            el = (
                <Plate
                    id={ data.scooter_id }
                    plateNo={ value }
                    onClick={ e => {
                        window.scrollTo({
                            top: 0,
                        });
                    } }
                    modal={ false }
                />
            );
            break;
        case 'is_activate':
            el = value ?
                <Translate value="scooter.activation.on" /> :
                <Translate value="scooter.activation.off" />;
            break;
        case 'rental_state':
            el = <RentalState status={ value } />;
            break;
        case 'soc':
            if (value === BATTERY_ERROR_204 || value === BATTERY_ERROR_238) {
                el = '--';
            }
            else {
                el = <BatteryCapacity className="profile-item" capacity={ value } hasPercentage={ false } />;
            }
            break;
        case 'tracker_soc':
            el = <BatteryCapacity className="profile-item" capacity={ value } hasPercentage={ false } />;
            break;
        case 'tracker_subtype_id':
            el = value ? <Translate value={ `ticket.sub_type_${ value }` } /> : undefined;
            break;
        case 'last_rental_end_time':
            el = value ? this.getDuration(now, moment(value)) : undefined;
            break;
        case 'last_connection_date':
            el = value ? this.getDuration(now, moment(value)) : undefined;
            break;
        case 'corporate_name':
            let corporateName = value ? value : 'GoShare';
            el = (
                <div className="scooter-cell">
                    <div className="scooter-cell-display">
                        <span>
                            { corporateName }
                        </span>
                    </div>
                </div>
            );
            break;
        case 'remaining_mileage':
            el = value !== null ? convertDistance(value, false) : '--';
            break;
        default:
            el = value;
            break;
        }
        return (
            <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }) => this.renderField({ data, width, name: key, }));
    }
}

export default ScooterListRow;
