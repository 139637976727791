import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { I18n } from 'react-redux-i18n';
import MultiselectFilter from './MultiselectFilter';
import {
    fetchScooterModels,
} from 'actions';

class ScooterModelFilter extends React.Component {
    static propTypes = {
        defaultSelected: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func.isRequired,
        scooterModels: ImmutablePropTypes.list.isRequired,
    }

    static defaultProps = {
        defaultSelected: []
    }

    componentDidMount() {
        const { dispatch, scooterModels } = this.props;
        if (scooterModels.size === 0) {
            dispatch(fetchScooterModels());
        }
    }

    render() {
        const { defaultSelected, onChange, scooterModels } = this.props;
        const scooterOptions = scooterModels.toJS().map(({ display_name }) => ({
            value: display_name,
            text: display_name,
        }));

        return (
            <MultiselectFilter
                title={ I18n.t('scooter.model') }
                options={ scooterOptions }
                defaultSelected={ defaultSelected }
                onChange={ onChange }
            />
        );
    }
}

export default connect(state => ({
    scooterModels: state.scooter.get('scooterModels'),
}))(ScooterModelFilter);
