import React from 'react';
import AuthView from 'components/AuthView';
import { ReactComponent as WelcomeTitle } from 'assets/img-fleet-title-blue.svg';

import './welcome.scss';

function Welcome() {
    return (
        <React.Fragment>
            <AuthView checkPermission={ false } className="welcome-page">
                <WelcomeTitle />
            </AuthView>
        </React.Fragment>);
}

export default Welcome;
