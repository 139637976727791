import store from 'store';
import { fetchZones } from 'actions';
import { polygon } from 'polygon-tools';

const { dispatch } = store;

const fetchData = (params = {}) => {
    return dispatch(fetchZones(params));
};
const addServiceZone = ({ map, params = {}, selected = false, vipLayer }) => {
    fetchData(params).then(
        ({ type, err, data = { data: [] } }) => {
            if ('ERROR' === type) {
                throw err;
            }
            map.data.forEach((feature) => {
                map.data.remove(feature);
            });

            let vips = selected && params.corporate_id.length === 0 ? [] :
                data.data.reduce((boundaries, zone) => [...boundaries, zone.boundary], []);
            if (!vipLayer) {
                vips = data.data.filter(zone => zone.type <= 1).reduce(
                    (boundaries, zone) => [...boundaries, zone.boundary],
                    []
                );
            }
            const worldBoundary = [
                [180, -90],
                [0, -90],
                [-180, -90],
                [-180, 90],
                [0, 90],
                [180, 90],
                [180, -90]
            ];
            let features = [];
            features = [
                ...features,
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: vips,
                    },
                    properties: {
                        name: 'vips',
                    }
                }, {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [worldBoundary, ...vips],
                    },
                    properties: {
                        name: 'vips_outside',
                    }
                },
            ];
            let union = polygon.union(...vips);

            // the first and last positions in a LinearRing of coordinates must be the same
            for (let i = 0; i < union.length; i += 1) {
                union[i].push(union[i][0]);
            }
            if (union.length !== 0) {
                features = [
                    ...features,
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: union,
                        },
                        properties: {
                            name: 'union',
                        }
                    },
                ];
            }
            map.data.addGeoJson({
                type: 'FeatureCollection',
                features
            });
            map.data.setStyle(feature => {
                const name = feature.getProperty('name');
                let layerStyle = {
                    strokeColor: 'transparent',
                    strokeWidth: 0,
                    fillColor: 'transparent',
                };
                switch (name) {
                case 'vips':
                    layerStyle = {
                        strokeColor: '#173e6f',
                        strokeWidth: 3,
                        fillColor: '#173e6f',
                        fillOpacity: 0.2,
                    };
                    break;
                case 'vips_outside':
                    layerStyle = {
                        strokeColor: 'transparent',
                        strokeWidth: 0,
                        fillColor: 'transparent',
                    };
                    break;
                case 'union':
                    layerStyle =  {
                        strokeColor: '#173e6f',
                        strokeWidth: 1,
                        fillColor: '#173e6f',
                        fillOpacity: 0.5,
                    };
                    break;
                default:
                    break;
                }
                return layerStyle;
            });
        }
    ).catch(err => {
        // Do something?
    });
};

export default addServiceZone;
