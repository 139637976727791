import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { overallListMapFitbound } from 'helpers/ticket-system';
import GoogleMap, { VIP_READ } from 'components/Map';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';
import addServiceZone from 'components/Map/Layers/service-zone';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
} from 'components/Map/Toolbox';
import './ticket-map.scss';
import usePrevious from "hooks/usePrevious";

const defaultToolbox = [CENTER_TO_CURRENT_LOCATION, VMS];

const TicketMap = ({ params, singleScooter, resetBounds }) => {
    const vipLayer = VIP_READ;
    const [map, setMap] = useState(undefined);
    const [maps, setMaps] = useState(undefined);
    const [scooterList, setScooterList] = useState([]);
    const prevList = usePrevious(scooterList);
    const toolboxList = getAuthedToolBoxList(defaultToolbox);
    const mapStyle = classNames({
        'map-view': true,
        'scooter-map-view': Object.keys(params).includes('scooterId')
    });

    const getOneScooterList = useCallback(
        () => {
            let result = [];
            if (singleScooter) {
                const { scooter_id, current_location, service_type, model_code } = singleScooter.toJS();
                const { lat, lng } = current_location;
                result = [{
                    id: scooter_id,
                    lat,
                    lng,
                    service_type,
                    model_code,
                    ...singleScooter.toJS(),
                }];
            }
            setScooterList(result);
        }, [singleScooter]);

    useEffect(() => {
        getOneScooterList();
    }, [getOneScooterList]);

    useEffect(() => {
        if (scooterList.length !== 0 && map !== undefined) {
            if (prevList[0]?.id !== scooterList[0].id) {
                overallListMapFitbound(scooterList, map, maps);
                addServiceZone({ map, params: { corporate_id: scooterList[0].corporate_id, }, vipLayer })
            }
        }
    }, [map, maps, prevList, scooterList, vipLayer]);

    const handleGoogleApi = ({ map, maps }) => {
        setMap(map);
        setMaps(maps);
    }

    return (
        <div className={ `ticket-system-map ${Object.keys(params).includes('scooterId') ? 'scooter-margin' : ''}` }>
            <GoogleMap
                className={ mapStyle }
                onInternalAPI={ handleGoogleApi }
                list={ scooterList }
                resetBounds={ resetBounds }
                toolbox={ toolboxList }
                currentLocation
                withoutScooterCluster
                vipLayer={ vipLayer }
                isDetail
            />
        </div>
    )
}

TicketMap.propTypes = {
    params: PropTypes.shape({}).isRequired,
}

export default connect((state, ownProps) => ({
    tasks: state.scooter.get('tasks'),
    oneTicket: state.ticket.get('oneTicket'),
    singleScooter: state.scooter.get('singleScooter')[ownProps.params.scooterId],
    currentGeoLocation: state.geolocation.get('currentLocation'),
    nearbyList: state.scooter.get('nearbyList'),
    i18n: state.i18n,
    selected: state.map.get('selectedIdMap'),
}))(TicketMap);
