import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Input, Label } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Datetime from 'react-datetime';
import DropDownList from './DropDownList';
import GroupSelection from './GroupSelection';
import UploadFile from './UploadFile';
import SearchSelect from './SearchSelect';
import ViewModeControl from './ViewModeControl';
import {
    TYPE_TEXT,
    TYPE_EMAIL,
    TYPE_FILE,
    TYPE_HIDDEN,
    TYPE_NUMBER,
    TYPE_PASSWORD,
    TYPE_URL,
    TYPE_TEXTAREA,
    TYPE_RADIO,
    TYPE_CHECKBOX,
    TYPE_DATETIME,
    TYPE_SELECT,
    TYPE_SEARCH_DROPDOWNLIST,
} from './input-type';

export * from './input-type';

class CustomInput extends Component {
    static propTypes = {
        type: PropTypes.oneOf([
            TYPE_TEXT,
            TYPE_EMAIL,
            TYPE_FILE,
            TYPE_HIDDEN,
            TYPE_NUMBER,
            TYPE_PASSWORD,
            TYPE_URL,
            TYPE_TEXTAREA,
            TYPE_RADIO,
            TYPE_CHECKBOX,
            TYPE_DATETIME,
            TYPE_SELECT,
            TYPE_SEARCH_DROPDOWNLIST,
        ]),
        viewMode: PropTypes.bool,
        name: PropTypes.string,
        innerRef: PropTypes.shape(),
        // translation key
        caption: PropTypes.string,
        unitCaption: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.shape({
                // translation key
                name: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.node,
                    PropTypes.bool
                ]),
            })),
            PropTypes.instanceOf(moment)
        ]),
        selected: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.arrayOf(PropTypes.bool)
        ]),
        onInput: PropTypes.func,
        withCaption: PropTypes.bool,
    };

    static defaultProps = {
        type: TYPE_TEXT,
        viewMode: false,
        name: undefined,
        innerRef: undefined,
        caption: '',
        required: false,
        disabled: false,
        value: '',
        selected: undefined,
        onInput: () => {},
        withCaption: true,
        unitCaption: undefined,
    };

    constructor(props) {
        super(props);

        const { innerRef, value, selected } = props;

        this.elInput = innerRef || React.createRef();
        this.simpleControlList = [
            TYPE_SELECT,
            TYPE_TEXT,
            TYPE_EMAIL,
            TYPE_HIDDEN,
            TYPE_NUMBER,
            TYPE_PASSWORD,
            TYPE_URL,
            TYPE_TEXTAREA,
        ];
        this.selectionControlList = [
            TYPE_RADIO,
            TYPE_CHECKBOX,
        ];

        this.customErrorMessage = '';
        this.hasValidated = false;

        this.state = {
            value,
            selected,
            invalidMessage: undefined,
        };
    }

    componentDidMount() {
        const input = this.elInput.current || {};
        input.setCustomErrorMessage = message => {
            input.setCustomValidity(message);
            this.customErrorMessage = message;
        };
    }

    handleInvalid = e => {
        e.preventDefault();
        this.hasValidated = true;

        this.checkValidity(e.currentTarget);
    }

    checkValidity = el => {
        const checkTypes = [
            'patternMismatch',
            'rangeOverflow',
            'rangeUnderflow',
            'tooLong',
            'tooShort',
            'typeMismatch',
            'valueMissing'
        ];
        let errorMessage = '';
        const { validity, title } = el;
        const { valid } = validity;
        if (!valid) {
            errorMessage = I18n.t('validation_error.generalInvalid');

            checkTypes.forEach(type => {
                if (validity[type]) {
                    errorMessage = I18n.t(`validation_error.${ type }`);
                }
            });

            errorMessage = this.customErrorMessage || title || errorMessage;

            this.setState({
                invalidMessage: errorMessage,
            });
        }
        else {
            this.setState({
                invalidMessage: '',
            });
        }
    }

    handleInput = e => {
        const { onInput } = this.props;

        if (this.hasValidated) {
            this.checkValidity(e.currentTarget);
        }

        onInput && onInput(e);
    }

    // NOTICE: this event is shared by native controllers and custom datetime
    // so `e` is may being the `event`, `key` or `moment`.
    handleChange = e => {
        const { onChange, type } = this.props;

        if (this.simpleControlList.indexOf(type) > -1) {
            this.setState({
                value: this.elInput.current.value,
            });
            this.handleInput(e);
        }
        else if (this.selectionControlList.indexOf(type) > -1) {
            return event => {
                const { selected = [] } = this.state;
                const realSelected = (type === TYPE_RADIO ? [] : [...selected]);
                const index = realSelected.indexOf(e);

                if (realSelected.indexOf(e) > -1) {
                    realSelected.splice(index, 1);
                }
                else {
                    realSelected.push(e);
                }

                this.setState({
                    selected: realSelected,
                });

                onChange && onChange(realSelected, event);
                this.handleInput(event);
            };
        }
        else if (type === TYPE_FILE && e.currentTarget.files.length > 0) {
            this.setState({
                value: e.currentTarget.files[0].name,
            });

            this.handleInput(e);
        }
        else if (type === TYPE_DATETIME) {
            const newState = {
                value: e,
            };

            if (e instanceof moment) {
                newState.invalidMessage = '';
            }

            this.setState(newState);
        }
        else if (type === TYPE_SEARCH_DROPDOWNLIST) {
            this.setState({
                invalidMessage: '',
            });
        }

        onChange && onChange(e);
    }

    getInputControlName = () => {
        const { type } = this.props;
        let el;

        switch (type) {
        case TYPE_RADIO:
        case TYPE_CHECKBOX:
            el = GroupSelection;
            break;
        case TYPE_DATETIME:
            el = Datetime;
            break;
        case TYPE_SELECT:
            el = DropDownList;
            break;
        case TYPE_FILE:
            el = UploadFile;
            break;
        case TYPE_SEARCH_DROPDOWNLIST:
            el = SearchSelect;
            break;
        case TYPE_TEXT:
        case TYPE_EMAIL:
        case TYPE_HIDDEN:
        case TYPE_NUMBER:
        case TYPE_PASSWORD:
        case TYPE_URL:
        default:
            el = Input;
        }

        return el;
    }

    getRealProps = () => {
        const {
            value,
            selected,
            open,
        } = this.state;
        const {
            type,
            name,
            required,
            disabled,
            readOnly,
        } = this.props;
        let inputProps = this.props;
        let extentionProps = {};

        switch (type) {
        case TYPE_RADIO:
        case TYPE_CHECKBOX:
            inputProps = {
                ...inputProps,
                selected,
                onInvalid: this.handleInvalid,
            };
            break;
        case TYPE_DATETIME:
            const convertedValue = value === 'Invalid date' ? undefined : value;

            inputProps = {
                ...inputProps,
                value: convertedValue,
                open,
                closeOnSelect: true,
                defaultValue: convertedValue,
                inputProps: {
                    name,
                    required,
                    disabled,
                    readOnly,
                    ref: this.elInput,
                    pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}[ ]?([0-9]{2}(:[0-9]{2}(:[0-9]{2})?)?)?$',
                    autoComplete: 'off',
                    onInvalid: this.handleInvalid,
                    onInput: this.handleInput,
                },
            };

            break;
        case TYPE_SELECT:
            inputProps = {
                ...inputProps,
                onInvalid: this.handleInvalid,
            };
            break;
        default:
            inputProps = {
                ...inputProps,
                value: value === null ? undefined : value,
                selected,
                autoComplete: 'off',
                onInvalid: this.handleInvalid,
                onInput: this.handleInput,
            };
            break;
        }

        inputProps = {
            ...inputProps,
            ...extentionProps,
            innerRef: this.elInput,
            onChange: this.handleChange,
        };
        delete inputProps.viewMode;
        delete inputProps.withCaption;
        delete inputProps.unitCaption;

        return inputProps;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            selected: nextProps.selected,
            invalidMessage: nextProps.invalidMessage,
        });
    }

    renderUnit() {
        const { unitCaption } = this.props;

        return unitCaption && (
            <Translate className="unitClass" tag="span" value={ unitCaption } />
        );
    }

    renderErrorToolTip() {
        const { viewMode } = this.props;
        const { invalidMessage } = this.state;

        return viewMode ? null : <p className="error-message">{ invalidMessage }</p>;
    }

    renderControl() {
        const { viewMode, value, type, selected } = this.props;
        const inputProps = this.getRealProps();
        const Control = this.getInputControlName();
        return (
            viewMode ?
                (
                    <ViewModeControl
                        value={ value }
                        type={ type }
                        selected={ selected }
                    />
                ) :
                (
                    <Control { ...inputProps } />
                )
        );
    }

    render() {
        const { type, caption, required, viewMode, disabled, withCaption } = this.props;
        const captionClass = classNames({
            caption: true,
            required: !viewMode && required,
        });
        const wrapperClass = classNames({
            'control-group': true,
            'view-mode': viewMode,
            'disabled': disabled,
        });
        const controlClass = classNames({
            'control-wrapper': true,
            'full-width': !withCaption,
        });
        const Wrapper = (viewMode || [TYPE_RADIO, TYPE_CHECKBOX, TYPE_DATETIME].indexOf(type) > -1 ? 'div' : Label);

        return (
            type === TYPE_HIDDEN ?
                this.renderControl() :
                (
                    <Wrapper className={ wrapperClass }>
                        {
                            withCaption ?
                                (
                                    <Translate className={ captionClass } value={ caption } tag="h5" />
                                ) :
                                null
                        }
                        <div className={ controlClass }>
                            <span className="inline-wrapper">
                                { this.renderControl() }
                                { this.renderUnit() }
                            </span>
                            { this.renderErrorToolTip() }
                        </div>
                    </Wrapper>
                )
        );
    }
}

export default CustomInput;
