import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import Smart from 'components/Smart';
import { selectScooter } from 'actions';
import { I18n } from 'react-redux-i18n';
import {
    fetchAllScooters,
    updateDocumentTitle,
    clearSingleScooter,
    clearAllScooters,
} from 'actions';
import FilterBar, {
    FILTER_SERVICE,
    FILTER_RENTAL_STATE,
    FILTER_BATTERY,
    FILTER_TRACKER_SOC,
    FILTER_MODEL,
    FILTER_CORPORATE
} from 'view/ScooterList/FilterBar';
import MapView from './MapView';
import './scooter-map.scss';

class ScooterMap extends Component {

    constructor(props) {
        super(props);

        const filter = {
            [FILTER_SERVICE]: undefined,
            [FILTER_RENTAL_STATE]: undefined,
            [FILTER_BATTERY]: undefined,
            [FILTER_TRACKER_SOC]: undefined,
            [FILTER_MODEL]: undefined,
            [FILTER_CORPORATE]: undefined,
        };

        this.state = {
            resetBounds: 0,
            queryPayload: {
                plate: undefined,
                filter,
            },
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(I18n.t(`routes.${ pathname }`)));
    }

    componentDidUpdate() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(I18n.t(`routes.${ pathname }`)));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
    }

    handleSubmit = value => {
        this.setState((prevState) => ({
            queryPayload: {
                ...prevState.queryPayload,
                filter: {},
                plate: value === '' ? undefined : value,
            }
        }), () => {
            this.fetchScooterList(true);
            this.forceUpdate();
        })
    }

    fetchScooterList = (isResetBounds = false) => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...params } = queryPayload;

        dispatch(clearAllScooters());
        return dispatch(fetchAllScooters({
            ...filter,
            ...params,
        })).then(() => {
            let { resetBounds } = this.state;

            if (isResetBounds) {
                resetBounds = Date.now();
            }

            this.setState({
                resetBounds,
            });
        });
    }

    handleFilterOn = (type, value) => {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;
        const { dispatch } = this.props;

        switch (type) {
        case FILTER_MODEL:
        case FILTER_SERVICE:
        case FILTER_RENTAL_STATE:
        case FILTER_CORPORATE:
            filter[type] = value.inputSelect;
            break;
        case FILTER_BATTERY:
        case FILTER_TRACKER_SOC:
            filter[`${type}_from`] = value.from;
            filter[`${type}_to`] = value.to;
            break;
        default:
        };

        this.setState((prevState) => ({
            queryPayload: {
                ...prevState.queryPayload,
                filter
            },
        }), () => {
            dispatch(selectScooter([]));
            this.fetchScooterList(true);
            this.forceUpdate();
        });
    }

    render() {
        const { resetBounds, queryPayload } = this.state;

        return (
            <AuthView className="scooter-map">
                <FilterBar
                    defaultSettings={ queryPayload }
                    onChange={ this.handleFilterOn }
                    onSubmit={ this.handleSubmit }
                />
                <Smart
                    fetch={ this.fetchScooterList }
                    refreshTime={ 0 }
                    pauseRefresh={ true }
                    tag=""
                    seamless
                >
                    <MapView
                        onFetch={ this.fetchScooterList }
                        resetBounds={ resetBounds }
                    />
                </Smart>
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(ScooterMap);
