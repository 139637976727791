/**
 * Define action type
 */
// general
export const ERROR = 'ERROR';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_ERROR_DIALOG = 'TOGGLE_ERROR_DIALOG';
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const OPEN_CATEGORY = 'OPEN_CATEGORY';
export const CLOSE_CATEGORY = 'CLOSE_CATEGORY';
export const OPEN_PLEASE_LOGIN_DIALOG = 'OPEN_PLEASE_LOGIN_DIALOG';

// async
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_FINISHED = 'ASYNC_FINISHED';

// task
export const FETCH_MY_TASK = 'FETCH_MY_TASK';
export const CLEAR_MY_TASK = 'CLEAR_MY_TASK';

// scooter
export const FETCH_SCOOTER_LIST = 'FETCH_SCOOTER_LIST';
export const CLEAR_SCOOTER_LIST = 'CLEAR_SCOOTER_LIST';
export const FETCH_SCOOTER_HISTORY = 'FETCH_SCOOTER_HISTORY';
export const CLEAR_SCOOTER_HISTORY = 'CLEAR_SCOOTER_HISTORY';
export const FETCH_SINGLE_SCOOTER = 'FETCH_SINGLE_SCOOTER';
export const CLEAR_SINGLE_SCOOTER = 'CLEAR_SINGLE_SCOOTER';
export const REMOTE_CONTROL = 'REMOTE_CONTROL';
export const BATCH_UPDATE_SERVICE_STATE = 'BATCH_UPDATE_SERVICE_STATEUPDATE';
export const OPEN_SCOOTER_DETAIL_POPUP = 'OPEN_SCOOTER_DETAIL_POPUP';
export const FETCH_SCOOTER_RENTAL_HISTORY = 'FETCH_SCOOTER_RENTAL_HISTORY';
export const CLEAR_SCOOTER_RENTAL_HISTORY = 'CLEAR_SCOOTER_RENTAL_HISTORY';
export const FORCE_RETURN = 'FORCE_RETURN';
export const GTU_REPLACEMENT = 'GTU_REPLACEMENT';
export const FETCH_SINGLE_SCOOTER_GTU = 'FETCH_SINGLE_SCOOTER_GTU';
export const CLEAR_SINGLE_SCOOTER_GTU = 'CLEAR_SINGLE_SCOOTER_GTU';
export const FETCH_SEARCHED_GTU_LIST = 'FETCH_SEARCHED_GTU_LIST';
export const CLEAR_SEARCHED_GTU_INFO = 'CLEAR_SEARCHED_GTU_INFO';
export const FETCH_NEARBY_SCOOTERS = 'FETCH_NEARBY_SCOOTERS';
export const CLEAR_NEARBY_SCOOTERS = 'CLEAR_NEARBY_SCOOTERS';
export const FETCH_ALL_SCOOTERS = 'FETCH_ALL_SCOOTERS';
export const CLEAR_ALL_SCOOTERS = 'CLEAR_ALL_SCOOTERS';
export const FETCH_SCOOTER_RELATED_TICKETS = 'FETCH_SCOOTER_RELATED_TICKETS';
export const CLEAR_SCOOTER_RELATED_TICKETS = 'CLEAR_SCOOTER_RELATED_TICKETS';
export const FETCH_SCOOTER_MODELS = 'FETCH_SCOOTER_MODELS';
export const FIRMWARE_VERSION = 'FIRMWARE_VERSION';
export const CLEAR_FIRMWARE_VERSION = 'CLEAR_FIRMWARE_VERSION';
export const FETCH_CORPORATES = 'FETCH_CORPORATES';
export const CLEAR_CORPORATES = 'CLEAR_CORPORATES';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const DELETE_FRF_COOKIE = 'DELETE_FRF_COOKIE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_PERMISSION = 'REFRESH_PERMISSION';
export const FETCH_OP_USERS = 'FETCH_OP_USERS';

// document meta
export const UPDATE_DOCUMENT_TITLE = 'UPDATE_DOCUMENT_TITLE';

// ticket
export const CREATE_TICKETS = 'CREATE_TICKETS';
export const UPDATE_ONE_TICKET = 'UPDATE_ONE_TICKET';
export const CLEAN_ONE_TICKET = 'CLEAN_ONE_TICKET';
export const UPDATE_MANY_TICKET = 'UPDATE_MANY_TICKET';
export const FETCH_TICKET_LIST = 'FETCH_TICKET_LIST';
export const FETCH_ONE_TICKET = 'FETCH_ONE_TICKET';
export const ASSIGN_SWAP_F_TICKET = 'ASSIGN_SWAP_F_TICKET';
export const FETCH_ALL_UNCLOSED_TICKET = 'FETCH_ALL_UNCLOSED_TICKET';
export const FETCH_ASSIGNMENT = 'FETCH_ASSIGNMENT';
export const INIT_ASSIGNMENTS = 'INIT_ASSIGNMENTS';

// map
export const FETCH_ZONES = 'FETCH_ZONES';
export const FETCH_VMS = 'FETCH_VMS';
export const FOOTER_CONTENT = 'FOOTER_CONTENT';
export const SELECT_SCOOTER = 'SELECT_SCOOTER';

// geo location
export const GET_GEOLOCATION = 'GET_GEOLOCATION';
export const CLEAR_GEOLOCATION = 'CLEAR_GEOLOCATION';
export const ERROR_GEOLOCATION = 'ERROR_GEOLOCATION';

// admin
export const FETCH_FLEET_ADMIN_USERS = 'FETCH_FLEET_ADMIN_USERS';
export const FETCH_FRANCHISEE_LIST = 'FETCH_FRANCHISEE_LIST';
export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const UPDATE_FLEET_ADMIN_USER = 'UPDATE_FLEET_ADMIN_USER';
export const UPDATE_MANY_FLEET_ADMIN_USERS = 'UPDATE_MANY_FLEET_ADMIN_USERS';
export const NEW_FLEET_USER = 'NEW_FLEET_USER';
