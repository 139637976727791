// Service Level Related
export const SERVICE_LEVEL_THRESHOLD = 78;
export const SERVICE_LEVEL_CHART_THICK = 10;
export const SCOOTER_MODEL_CHART_THICK = 20;

// Style
export const DASH_COLOR_BLUE = '#0275ff';
export const DASH_COLOR_YELLOW = '#ffb800';
export const DASH_COLOR_WHITE = '#ffffff';

// Dashboard Type
export const DASH_OVERALL = 'DASH_OVERALL';
export const DASH_DOTS = 'DASH_DOTS';
export const DASH_EMPTY = 'DASH_EMPTY';

// Scooter Model Group
export const DASH_SCOOTER_MODEL_GROUP_P = 'P';
export const DASH_SCOOTER_MODEL_GROUP_B = 'B';
export const DASH_SCOOTER_MODEL_GROUP_F = 'F';
export const DASH_SCOOTER_MODEL_GROUP_J = 'J';

export const DASH_SCOOTER_MODEL_COLOR_ON_P = '#8be561';
export const DASH_SCOOTER_MODEL_COLOR_OFF_P = '#b4e39e';
export const DASH_SCOOTER_MODEL_COLOR_ON_B = '#00d7ff';
export const DASH_SCOOTER_MODEL_COLOR_OFF_B = '#80ebff';
export const DASH_SCOOTER_MODEL_COLOR_ON_F = '#ff7056';
export const DASH_SCOOTER_MODEL_COLOR_OFF_F = '#ffb7aa';
export const DASH_SCOOTER_MODEL_COLOR_ON_J = '#edd90c';
export const DASH_SCOOTER_MODEL_COLOR_OFF_J = '#fdf076';
export const DASH_SCOOTER_MODEL_COLOR_ON_J_DARK = '#edd90c';
export const DASH_SCOOTER_MODEL_COLOR_OFF_J_DARK = '#fdf076';

// Tickets Category Show On Dashboard
export const DASH_TICKET_SWAP = 'TICKET_SWAP';
export const DASH_TICKET_MAINTAIN = 'TICKET_MAINTAIN';
export const DASH_TICKET_DISPATCH = 'TICKET_DISPATCH';
export const DASH_TICKET_OTHER = 'TICKET_OTHER';
export const DASH_TICKET_NONE = 'TICKET_NONE';

export const DASH_TICKET_SWAP_COLOR = '#0275ff';
export const DASH_TICKET_MAINTAIN_COLOR = '#00d3d8';
export const DASH_TICKET_DISPATCH_COLOR = '#00b0d0';
export const DASH_TICKET_OTHER_COLOR = '#2e72bc';
export const DASH_TICKET_NONE_COLOR = '#7c8c8f';

export const DASH_TICKET_SWAP_THRESHOLD = 0.065;
export const DASH_TICKET_MAINTAIN_THRESHOLD = 0.05;
export const DASH_TICKET_DISPATCH_THRESHOLD = 0.035;
export const DASH_TICKET_OTHER_THRESHOLD = 0.03;
export const DASH_TICKET_NONE_THRESHOLD = 0.02;

export const TICKETS_CATEGORY_BY_ORDER = [
    {
        id: DASH_TICKET_SWAP,
        style: DASH_TICKET_SWAP_COLOR,
        threshold: {
            value: DASH_TICKET_SWAP_THRESHOLD,
            unitByPercent: true,
        },
    }, {
        id: DASH_TICKET_MAINTAIN,
        style: DASH_TICKET_MAINTAIN_COLOR,
        threshold: {
            value: DASH_TICKET_MAINTAIN_THRESHOLD,
            unitByPercent: true,
        },
    }, {
        id: DASH_TICKET_DISPATCH,
        style: DASH_TICKET_DISPATCH_COLOR,
        threshold: {
            value: DASH_TICKET_DISPATCH_THRESHOLD,
            unitByPercent: true,
        },
    }, {
        id: DASH_TICKET_OTHER,
        style: DASH_TICKET_OTHER_COLOR,
        threshold: {
            value: DASH_TICKET_OTHER_THRESHOLD,
            unitByPercent: true,
        },
    }, {
        id: DASH_TICKET_NONE,
        style: DASH_TICKET_NONE_COLOR,
        threshold: {
            value: DASH_TICKET_NONE_THRESHOLD,
            unitByPercent: true,
        },
    }
];
