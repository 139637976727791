import React from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/Filter';
import SearchField from 'components/SearchField';
import { SEPARATOR } from 'components/Form/Input/SearchSelect';
import { Input, Label } from 'reactstrap';

class MultiselectFilter extends React.Component {

    static propTypes = {
        defaultSelected: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.array,
            PropTypes.string,
        ])),
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.node,
            text: PropTypes.string,
        })).isRequired,
        onChange: PropTypes.func,
        searchable: PropTypes.bool,
        single: PropTypes.bool,
        textClass: PropTypes.string,
    }

    static defaultProps = {
        defaultSelected: [],
        title: '',
        onChange: () => {},
        searchable: false,
        single: false,
        textClass: '',
    }

    constructor(props) {
        super(props);
        const { defaultSelected, options } = props;
        this.defineSelectedOptions({ defaultSelected, options });
        this.state = {
            open: false,
            keyword: '',
        };
    }

    shouldComponentUpdate(nextProps) {
        this.defineSelectedOptions(nextProps);
        return true;
    }

    handleFilterReset = () => {
        const { onChange } = this.props;

        this.setState({
            open: false,
            keyword: '',
        });
        this.selectedOptionMap = {};
        onChange({ inputSelect: [] });
    }

    handleFilterApply = () => {
        const { onChange } = this.props;
        const inputSelect = Object.values(this.selectedOptionMap);
        this.setState({
            open: false,
            keyword: '',
        });
        onChange({
            inputSelect: inputSelect.map(({ value }) => value),
        });
    }

    handleFilterToggle = () => {
        const { open } = this.state;
        this.selectedOptionMap = {};

        this.setState({
            keyword: '',
            open: !open,
        });
    }

    handleKeywordChange = keyword => {
        this.setState({
            keyword,
        });
    }

    handleChange = ({ text, value }) => e => {
        const { single } = this.props;

        if (e.currentTarget.checked) {
            if (single) {
                this.selectedOptionMap = [];
            }

            this.selectedOptionMap[value] = { text, value };
        }
        else {
            delete this.selectedOptionMap[value];
        }
    }

    defineSelectedOptions({ defaultSelected, options }) {
        this.selectedOptionMap = options.reduce((obj, item) => {
            const { value } = item;
            if (Array.isArray(value)) {
                defaultSelected.forEach(element => {
                    if (element.toString() === value.toString()) {
                        obj[value.toString()] = item;
                    }
                });
            }
            else if (defaultSelected.indexOf(value) > -1) {
                obj[value] = item;
            }

            return obj;
        }, {});
    }

    renderSearchField() {
        const { searchable } = this.props;
        const { keyword } = this.state;
        let el;

        if (searchable) {
            el = (
                <SearchField
                    stopTriggerByEnter
                    tag="div"
                    onChange={ this.handleKeywordChange }
                    value={ keyword }
                />
            );
        }

        return el;
    }

    render() {
        const { open, keyword } = this.state;
        const inputSelect = Object.values(this.selectedOptionMap);
        const { title, options, single, textClass } = this.props;
        const applied = inputSelect.length > 0;
        const InputOptions = () => {
            return options.map(({ text, value }) => {
                if (text === SEPARATOR) {
                    return null;
                }

                return (
                    text.toUpperCase().indexOf(keyword.toUpperCase()) > -1 ?
                        (
                            <Label className="filter-option" key={ value }>
                                <Input
                                    type={ single ? 'radio' : 'checkbox' }
                                    name={ single ? title : undefined }
                                    value={ value }
                                    defaultChecked={
                                        this.selectedOptionMap[value.toString()] !== undefined
                                    }
                                    onChange={ this.handleChange({ text, value }) }
                                />
                                <span className={ textClass }>{ text }</span>
                            </Label>
                        ) :
                        null
                );
            });
        };
        const maxCount = 3;
        const filterValueArry = [];

        for (let i = 0; i < Math.min(maxCount, inputSelect.length); i++) {
            filterValueArry.push(inputSelect[i].text);
        }

        if (inputSelect.length > maxCount) {
            filterValueArry.push('...');
        }

        const filterValue = filterValueArry.length ? `${ title }: ${ filterValueArry.toString() }` : title;

        return (
            <Filter
                className="multiselect-filter"
                value={ filterValue }
                open={ open }
                applied={ applied }
                onToggle={ this.handleFilterToggle }
                onReset={ this.handleFilterReset }
                onApply={ this.handleFilterApply }
            >
                { this.renderSearchField() }
                <div className="option-container">
                    <InputOptions />
                </div>
            </Filter>
        );
    }
}
export default MultiselectFilter;
