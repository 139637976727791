import { createFleetApiInstanceWithToken } from '../util';


const mission = {
    fetchMissionList(params) {
        return createFleetApiInstanceWithToken().get('/missions', { params });
    },

    fetchOneMission(id) {
        return createFleetApiInstanceWithToken().get(`/missions/${ id }`);
    },

    updateOneMission(id, params) {
        return createFleetApiInstanceWithToken().patch(`/missions/${ id }`, params);
    },

    createOneMission(params) {
        return createFleetApiInstanceWithToken().post('/missions', params);
    },
};

export default mission;
