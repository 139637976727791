import {
    EMAIL_FORMAT_INVALID,
    STATUS_INVALID,
    ROLE_ID_INVALID,
    ACCOUNT_ALREADY_EXISTS,
} from 'constants/admin';

const admin = {
    account: 'Account',
    name: 'Name',
    role_name: 'Role',
    new_user: 'New',
    remove: 'Remove',
    role: 'Role',
    update_user: 'Edit User',
    update_users: 'Edit Users',
    confirm_remove: 'Delete users:',
    [`update_user_error_message_${ EMAIL_FORMAT_INVALID }`]: 'Email format invalid',
    [`update_user_error_message_${ STATUS_INVALID }`]: 'Status invalid',
    [`update_user_error_message_${ ROLE_ID_INVALID }`]: 'Role id invalid',
    [`update_user_error_message_${ ACCOUNT_ALREADY_EXISTS }`]: 'Account already exists',
};

export default admin;
