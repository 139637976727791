import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { getRentalStatusOptions } from 'components/Scooter/RentalState';
import FlexGroup from 'components/FlexGroup';
import SearchField from 'components/SearchField';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import NumberRangeFilter from 'components/Filter/NumberRangeFilter';
import ScooterModelFilter from 'components/Filter/ScooterModelFilter';
import {
    SCOOTER_OFFLINE,
    SCOOTER_ONLINE,
} from 'constants/scooter';
import CorporatesFilter from "components/Filter/CorporatesFilter";
import {
    fetchCorporatesList,
    clearCorporatesList
} from 'actions';

export const FILTER_SERVICE = 'is_activate';
export const FILTER_RENTAL_STATE = 'rental_state';
export const FILTER_BATTERY = 'soc';
export const FILTER_TRACKER_SOC = 'tracker_soc';
export const FILTER_MAIN_TYPE = 'tracker_id';
export const FILTER_SUB_TYPE = 'tracker_subtype_id';
export const FILTER_TAG_ASSIGNEE = 'tag_assignee';
export const FILTER_MODEL = 'model_display_name';
export const FILTER_CORPORATE = 'corporate_id';

const Component = ({ defaultSettings, onSubmit, onChange, excludeList, isMasterCorporate, corporate, dispatch }) => {
    const availabilityOptions = [
        SCOOTER_OFFLINE,
        SCOOTER_ONLINE,
    ].map(
        value => ({
            text: I18n.t(`scooter.availability_state_map.${ value }`),
            value,
        })
    );

    useEffect(() => {
        if (corporate.size === 0) {
            dispatch(fetchCorporatesList());
        }
    }, [dispatch, corporate]);

    useEffect(() => {
        return () => {
            dispatch(clearCorporatesList());
        }
    }, [dispatch]);

    const handleChange = type => value => {
        onChange(type, value);
    };
    const { filter } = defaultSettings;
    const FilterWrapper = ({ hide = false, children }) => {
        if (hide) {
            return null;
        }
        else {
            return children;
        }
    };

    return (
        <FlexGroup start>
            <SearchField
                placeholder={ I18n.t('search') }
                onSubmit={ onSubmit }
                value={ defaultSettings.plate }
            />
            <section className="filters">
                <FilterWrapper hide={ excludeList.includes(FILTER_SERVICE) }>
                    <MultiselectFilter
                        title={ I18n.t('scooter.service') }
                        options={ availabilityOptions }
                        defaultSelected={ filter[FILTER_SERVICE] }
                        onChange={ handleChange(FILTER_SERVICE) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_RENTAL_STATE) }>
                    <MultiselectFilter
                        title={ I18n.t('scooter.rental_state') }
                        options={ getRentalStatusOptions(true) }
                        defaultSelected={ filter[FILTER_RENTAL_STATE] }
                        onChange={ handleChange(FILTER_RENTAL_STATE) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_BATTERY) }>
                    <NumberRangeFilter
                        title={ I18n.t('scooter.battery_with_unit') }
                        defaultFrom={ filter[`${ FILTER_BATTERY }_from`] }
                        defaultTo={ filter[`${ FILTER_BATTERY }_to`] }
                        onApply={ handleChange(FILTER_BATTERY) }
                        applied={
                            typeof filter[`${ FILTER_BATTERY }_from`] === 'number' &&
                            typeof filter[`${ FILTER_BATTERY }_to`] === 'number'
                        }
                        min={ 0 }
                        max={ 100 }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_TRACKER_SOC) }>
                    <NumberRangeFilter
                        title={ I18n.t('scooter.tracker_soc_with_unit') }
                        defaultFrom={ filter[`${ FILTER_TRACKER_SOC }_from`] }
                        defaultTo={ filter[`${ FILTER_TRACKER_SOC }_to`] }
                        onApply={ handleChange(FILTER_TRACKER_SOC) }
                        applied={
                            typeof filter[`${ FILTER_TRACKER_SOC }_from`] === 'number' &&
                            typeof filter[`${ FILTER_TRACKER_SOC }_to`] === 'number'
                        }
                        min={ 0 }
                        max={ 100 }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_MODEL) }>
                    <ScooterModelFilter
                        defaultSelected={ filter[FILTER_MODEL] }
                        onChange={ handleChange(FILTER_MODEL) }
                    />
                </FilterWrapper>
                {
                    isMasterCorporate && (
                        <FilterWrapper hide={ excludeList.includes(FILTER_CORPORATE) }>
                            <CorporatesFilter
                                defaultSelected={ filter[FILTER_CORPORATE] }
                                onChange={ handleChange(FILTER_CORPORATE) }
                                list={ corporate.toJS() }
                            />
                        </FilterWrapper>
                    )
                }
            </section>
        </FlexGroup>
    );
};
Component.propTypes = {
    defaultSettings: PropTypes.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    excludeList: PropTypes.arrayOf(PropTypes.oneOf([
        FILTER_SERVICE,
        FILTER_RENTAL_STATE,
        FILTER_BATTERY,
        FILTER_TRACKER_SOC,
        FILTER_MAIN_TYPE,
        FILTER_SUB_TYPE,
        FILTER_TAG_ASSIGNEE,
        FILTER_MODEL,
        FILTER_CORPORATE
    ])),
    corporate: ImmutablePropTypes.list,
};
Component.defaultProps = {
    excludeList: [],
    corporate: List([])
};

export default connect(state => ({
    i18n: state.i18n,
    corporate: state.scooter.get('corporate'),
    isMasterCorporate: state.scooter.get('isMasterCorporate'),
}))(Component);
