import React from 'react';

import './search-result-wrap.scss';

const SearchResultWrap = ({ show, children }) => {
    const noMatch = (
        <div className="no-match">
            <h2>No Match</h2>
        </div>
    );
    return show ? children : noMatch;
};

export default SearchResultWrap;
