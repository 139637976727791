import React from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/Filter';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup, Label } from 'reactstrap';
import './filter.scss';


class DatetimeRangeFilter extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        onApply: PropTypes.func,
        dateFormat: PropTypes.string,
        timeFormat: PropTypes.string,
        applied: PropTypes.bool,
        range: PropTypes.arrayOf(propValue => {
            if (!Array.isArray(propValue)) {
                return new Error('Is not an array');
            }

            const unitList = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
            const [ num, unit ] = propValue;

            if (typeof num !== 'number') {
                return new Error('Wrong type');
            }

            if (unitList.indexOf(unit) === -1) {
                return new Error('Unsupported unit name');
            }

            return true;
        }),
    }

    static defaultProps = {
        title: '',
        onApply: () => {},
        dateFormat: I18n.t('datetime_format.date'),
        timeFormat: I18n.t('datetime_format.time'),
        applied: false,
        range: undefined,
    }

    constructor(props) {
        super(props);

        this.state = this.initialState(props);
    }

    componentDidUpdate(prevProps) {
        // when prev applied = true and then false. reset values
        const { applied } = this.props;

        if (applied === false && prevProps.applied === true) {
            this.setState({
                inputFrom: undefined,
                inputTo: undefined,
            });
        }
    }

    handleFilterToggle = () => {
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    }

    handleFilterReset = () => {
        const { onApply } = this.props;

        onApply({
            from: undefined,
            to: undefined,
        });

        this.setState({
            open: false,
        });
    }

    handleFilterApply = () => {
        const { errorMessage, inputFrom, inputTo } = this.state;
        const { onApply } = this.props;

        if (!errorMessage) {
            this.setState({
                open: false,
                toMin: moment('1900-01-01'),
                toMax: moment('2999-01-01'),
            });

            onApply({
                from: inputFrom,
                to: inputTo,
            });
        }
    }

    getTitle = () => {
        const { errorMessage, inputFrom, inputTo } = this.state;
        const { dateFormat, title, applied } = this.props;
        let displayTitle = title;

        if (!errorMessage && inputFrom && inputTo && applied) {
            const displayFrom = inputFrom ? moment(inputFrom).format(dateFormat) : '';
            const displayTo = inputTo ? moment(inputTo).format(dateFormat) : '';

            displayTitle = title ? `${ title }: ${ displayFrom }` : displayFrom;

            if (displayFrom !== displayTo) {
                displayTitle = displayTitle + ` - ${ displayTo }`;
            }
        }

        return displayTitle;
    }

    handleFilterClickOutside = () => {
        this.setState({ open: false });
    }

    handleChange = field => inputValue => {
        const { dateFormat, timeFormat } = this.props;
        const fullFormat = `${ dateFormat } ${ timeFormat }`;

        if (moment(inputValue, fullFormat, true).isValid()) {
            const { toMin, toMax } = this.state;
            const { range } = this.props;
            let updatedMin = field === 'inputFrom' ? moment(inputValue).subtract(1, 'days') : toMin;
            let updatedMax = toMax;
            let errorMessage = '';
            const updatedState = {
                ...this.state,
                toMin: updatedMin,
                [field]: moment(inputValue),
            };
            const { inputFrom, inputTo } = updatedState;

            updatedState.toMin = updatedMin;

            if (!inputFrom || !inputTo) {
                errorMessage = I18n.t('validation_error.require_both_dates');
            }
            else if (moment(inputTo).diff(moment(inputFrom)) < 0) {
                errorMessage = I18n.t('validation_error.wrong_dates_order');
            }
            else if (range && moment(inputTo).diff(moment(inputFrom), range[1]) > range[0]) {
                const unitText = I18n.t(range[1]);

                errorMessage = I18n.t('validation_error.out_of_duration', {
                    num: range[0],
                    unit: unitText,
                });
            }
            else {
                errorMessage = '';
            }

            if (field === 'inputFrom') {
                if (range) {
                    updatedMax = moment(inputValue).add(...range).add(1, 'days');
                }

                updatedState.toMax = updatedMax;

                const isEndTimeInRange = inputTo && moment(inputTo).isBetween(updatedMin, updatedMax);

                if (isEndTimeInRange === false) {
                    updatedState.inputTo = undefined;
                    errorMessage = I18n.t('validation_error.require_both_dates');
                }
            }

            updatedState.errorMessage = errorMessage;

            this.setState(updatedState);
        }
    }

    initialState(props) {
        return {
            open: false,
            errorMessage: undefined,
            inputFrom: undefined,
            inputTo: undefined,
            toMin: moment('1900-01-01'),
            toMax: moment('2999-01-01'),
        };
    }

    render() {
        const { dateFormat, timeFormat, applied } = this.props;
        const { inputFrom, inputTo, errorMessage, open, toMin, toMax } = this.state;
        const DatetimeInput = inputProps => {
            const { onChange, ...props } = inputProps;

            return (
                <div className="input-wrap">
                    <input
                        { ...props }
                        className="datetime-box"
                        onChange={ onChange }
                        onPaste={ e => e.preventDefault() }
                        required
                    />
                </div>
            );
        };

        return (
            <Filter
                className="datetime-range-filter"
                value={ this.getTitle() }
                errorMessage={ errorMessage }
                open={ open }
                applied={ applied }
                onToggle={ this.handleFilterToggle }
                onReset={ this.handleFilterReset }
                onApply={ this.handleFilterApply }
                onClickOutside={ this.handleFilterClickOutside }
            >
                <section className="form-groups">
                    <FormGroup>
                        <Label for="from">
                            <Translate value="from" />
                        </Label>
                        <Datetime
                            value={ inputFrom }
                            className="datetime-picker"
                            dateFormat={ dateFormat }
                            timeFormat={ timeFormat }
                            renderInput={ DatetimeInput }
                            inputProps={ {
                                onChange: () => {},
                            } }
                            open
                            onChange={ this.handleChange('inputFrom') }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="to">
                            <Translate value="to" />
                        </Label>
                        <Datetime
                            value={ inputTo }
                            className="datetime-picker"
                            dateFormat={ dateFormat }
                            timeFormat={ timeFormat }
                            renderInput={ DatetimeInput }
                            inputProps={ {
                                onChange: () => {},
                            } }
                            open
                            isValidDate={ current => current.isBetween(toMin, toMax) }
                            onChange={ this.handleChange('inputTo') }
                        />
                    </FormGroup>
                </section>
            </Filter>
        );
    }
}
export default DatetimeRangeFilter;
