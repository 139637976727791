/**
 * Reducer - Account
 */
import { Map, List } from 'immutable';
import {
    LOGIN,
    LOGOUT,
    REFRESH_PERMISSION,
    FETCH_OP_USERS,
    OPEN_CATEGORY,
    CLOSE_CATEGORY,
} from 'constants/action-type';
import {
    AUTH_BY_PASS
} from 'constants/routes-with-auth';
import {
    NORMAL_USER,
    VIRTURL_USER
} from 'constants/opUser';

import { SEPARATOR } from 'components/Form/Input/SearchSelect';
import account from 'helpers/account';
import creater from './util/creater';
import { defaultOpenCategoryList } from 'components/Sidebar/get-hierarchical-menu';

// fetchOperationUsers
const isLoggedIn = account.exists();
const profile = account.get();
const initialState = Map({
    isLoggedIn,
    profile: (isLoggedIn ? Map(profile) : undefined),
    permission: undefined,
    openCategoryList: List([]),
    opUsers: List([]),
    virtualUsers: List([]),
});

const actionsMap = {
    [LOGIN]: (state, action) => {
        const { profile, permission } = action.data;
        const { profile: userProfile, expires_in } = profile;
        const res = { ...userProfile, expires_in };
        account.set(res);
        account.setID(profile.id_token);
        account.setProfile(res);
        account.setExpiresAt(res);
        permission.authorities.push({ authority: AUTH_BY_PASS });
        return state.merge({
            isLoggedIn: true,
            profile: Map(userProfile),
            permission: Map(permission),
        });
    },

    [REFRESH_PERMISSION]: (state, action) => {
        const openCategoryList = state.get('openCategoryList');
        const list = openCategoryList.toJS().concat(defaultOpenCategoryList(action.data.authorities));
        action.data.authorities.push({ authority: AUTH_BY_PASS });
        return state.merge({
            permission: Map(action.data),
            openCategoryList: List(list),
        });
    },

    [OPEN_CATEGORY]: (state, action) => {
        const openCategoryList = state.get('openCategoryList');
        let list = openCategoryList.toJS();
        const { category } = action.data;
        return state.merge({
            openCategoryList: (list.indexOf(category) > -1) ? openCategoryList : List([...list, category]),
        });
    },

    [CLOSE_CATEGORY]: (state, action) => {
        const openCategoryList = state.get('openCategoryList');
        const { category } = action.data;
        const list = openCategoryList.toJS().filter(item => item !== category);
        return state.merge({
            openCategoryList: List(list),
        });
    },

    [FETCH_OP_USERS]: (state, action) => {
        const virtualUsers = action.data.filter(user => user.user_type === VIRTURL_USER);
        const users = action.data.filter(user => user.user_type === NORMAL_USER);
        const seperator = { name: SEPARATOR };
        const opUsers = [...virtualUsers, seperator, ...users];

        return state.merge({
            virtualUsers: List(virtualUsers),
            opUsers: List(opUsers),
        });
    },

    [LOGOUT]: (state) => {
        account.remove();
        account.setID('');
        account.setProfile(null);
        account.setExpiresAt(null);

        return state.merge({
            isLoggedIn: false,
            profile: null,
            permission: null,
            openCategoryList: List([]),
        });
    },
};

export default creater(actionsMap, initialState);
