import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button } from 'reactstrap';
import mobile from 'is-mobile';
import { Translate } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import Pagination, { TotalCount, Refresh } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import TotalSelection from 'components/TotalSelection';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import UpdateUser from 'components/UpdateUser/UpdateUser';
import NewUser from 'components/NewUser/NewUser';
import RemoveUser from 'components/RemoveUser/RemoveUser';
import AuthFeature from 'components/AuthFeature';
import permissionHandler from 'helpers/permission-handler';
import { AUTH_EDIT_FLEET_USER } from 'constants/permission';
import { getHeaderConfig } from './ListHeader';
import AdminListRow from './AdminListRow';

export const PAGE_INDEX = 'PAGE_INDEX';
export const PAGE_SIZE = 'PAGE_SIZE';
export const SORT = 'SORT';

class AdminList extends React.Component {
    static propTypes = {
        fleetAdminUsers: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        pageSize: PropTypes.number.isRequired,
        selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
        onFetch: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            showUpdateUserModal: false,
            showNewUserModal: false,
            showRemoveUserModal: false,
        };
    }

    setSelectById = (ids, selectAll) => {
        const { fleetAdminUsers, onSelect, selectedIdList } = this.props;
        const { data_list } = fleetAdminUsers.toJS();
        let updatedScooterList = [...selectedIdList];

        if (selectAll) {
            updatedScooterList = ids.length === data_list.length ? ids : [];
        }
        else {
            ids.forEach(id => {
                if (updatedScooterList.indexOf(id) === -1) {
                    updatedScooterList.push(id);
                }
                else {
                    const idx = updatedScooterList.indexOf(id);
                    updatedScooterList.splice(idx, 1);
                }
            });
        }
        onSelect(updatedScooterList);
    }

    toggleUpdateUserModal = () => {
        this.setState(state => ({
            showUpdateUserModal: !state.showUpdateUserModal
        }));
    }

    toggleNewUserModal = () => {
        this.setState(state => ({
            showNewUserModal: !state.showNewUserModal
        }));
    }

    toggleRemoveUserModal = () => {
        this.setState(state => ({
            showRemoveUserModal: !state.showRemoveUserModal
        }));
    }

    handleListSort = ({ field, order }) => {
        const { onChange } = this.props;
        let sort;

        if (field && order) {
            sort = `${ field },${ order }`;
        }

        onChange(SORT)({ sort, page: 1 });
    }

    handleUpdated = () => {
        const { onSelect, onFetch } = this.props;
        onFetch();
        this.toggleUpdateUserModal();
        onSelect([]);
    }

    handleCreated = () => {
        const { onFetch } = this.props;
        onFetch();
        this.toggleNewUserModal();
    }

    handleRemoved = () => {
        const { onFetch, onSelect } = this.props;
        onFetch();
        onSelect([]);
        this.toggleRemoveUserModal();
    }

    handleAccountFieldClick = id => {
        const { onSelect } = this.props;
        onSelect([id]);
        this.toggleUpdateUserModal();
    }

    render() {
        const { showUpdateUserModal, showNewUserModal, showRemoveUserModal } = this.state;
        const {
            selectedIdList,
            onFetch,
            fleetAdminUsers,
            pageSize,
            onChange,
        } = this.props;
        const { data_list, page_index, page_count, total, __responseTime } = fleetAdminUsers.toJS();
        const ListItem = ({ columns, rowData }) => {
            rowData.handleAccountFieldClick = () => {
                this.handleAccountFieldClick(rowData.id);
            };

            return (
                <AdminListRow
                    key={ `scooter-${ rowData.scooter_id }` }
                    columns={ columns }
                    data={ rowData }
                />
            );
        };

        return (
            <React.Fragment>
                <FlexGroup>
                    <FlexGroup className="admin-list-control-section" start>
                        <AuthFeature requiredList={ [ AUTH_EDIT_FLEET_USER ] }>
                            <Button
                                className="btn-update"
                                color="primary"
                                type="button"
                                onClick={ this.toggleUpdateUserModal }
                                disabled={ selectedIdList.length === 0 }
                            >
                                <Translate value="edit" />
                            </Button>
                            <Button
                                className="btn-new"
                                type="button"
                                onClick={ this.toggleNewUserModal }
                                outline
                            >
                                <Translate value="admin.new_user" />
                            </Button>
                            <Button
                                className="btn-remove"
                                type="button"
                                disabled={ selectedIdList.length === 0 }
                                onClick={ this.toggleRemoveUserModal }
                                outline
                            >
                                <Translate value="admin.remove" />
                            </Button>
                        </AuthFeature>
                        <Pagination
                            page={ page_index }
                            total={ page_count }
                            offset={ pageSize }
                            onSelect={ page => onChange(PAGE_INDEX)({ page }) }
                        />
                        <PageSizeSelect
                            onChange={ selection => onChange(PAGE_SIZE)({ size: selection.value, page: 1 }) }
                        />
                        <TotalCount count={ total } />
                        <TotalSelection count={ selectedIdList.length } />
                    </FlexGroup>
                    <FlexGroup end>
                        <Refresh onClick={ onFetch } time={ __responseTime } />
                    </FlexGroup>
                </FlexGroup>

                <section className="admin-list-table">
                    <Smart
                        tag=""
                        seamless
                        refreshTime={ 60 }
                        fetch={ onFetch }
                        pauseRefresh={ selectedIdList.length > 0 }
                    >
                        <ListView
                            selectable={ permissionHandler({ requiredList: [AUTH_EDIT_FLEET_USER] }) }
                            header={ getHeaderConfig() }
                            dataKey="id"
                            onSort={ this.handleListSort }
                            onSelect={ this.setSelectById }
                            defaultSelect={ selectedIdList }
                            list={ data_list }
                            renderListRow={ ListItem }
                            fixed={ mobile() }
                        />
                    </Smart>
                </section>
                { showUpdateUserModal && (
                    <UpdateUser
                        users={ data_list }
                        selectedIdList={ selectedIdList }
                        onClose={ this.toggleUpdateUserModal }
                        onUpdated={ this.handleUpdated }
                    />
                ) }
                { showNewUserModal && (
                    <NewUser
                        onClose={ this.toggleNewUserModal }
                        onCreated={ this.handleCreated }
                    />
                ) }
                { showRemoveUserModal && (
                    <RemoveUser
                        users={ data_list }
                        selectedIdList={ selectedIdList }
                        onClose={ this.toggleRemoveUserModal }
                        onRemoved={ this.handleRemoved }
                    />
                ) }
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    fleetAdminUsers: state.admin.get('list'),
}))(AdminList);
