import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import RingSpinner from 'components/Spinner/Ring';
import {
    RPC_LOCK,
    RPC_UNLOCK,
    RPC_OPEN_TRUNK,
    RPC_FIND_SCOOTER,
} from 'constants/scooter';
import { sendRemoteCommand } from 'actions';

import './remote-control.scss';

const Icon = ({ className, children }) => <span className={ `icon ${ className }` } />;

Icon.propTypes = {
    className: PropTypes.string,
};
Icon.defaultProps = {
    className: '',
};

const typeMap = {
    [RPC_LOCK]: {
        icon: 'rpc-lock',
        titleCode: 'scooter.lock',
        action: id => sendRemoteCommand(id, RPC_LOCK),
    },
    [RPC_UNLOCK]: {
        icon: 'rpc-unlock',
        titleCode: 'scooter.unlock',
        action: id => sendRemoteCommand(id, RPC_UNLOCK),
    },
    [RPC_OPEN_TRUNK]: {
        icon: 'rpc-opencart',
        titleCode: 'scooter.open_trunk',
        action: id => sendRemoteCommand(id, RPC_OPEN_TRUNK),
    },
    [RPC_FIND_SCOOTER]: {
        icon: 'rpc-search-location',
        titleCode: 'scooter.find',
        action: id => sendRemoteCommand(id, RPC_FIND_SCOOTER),
    },
};

class RemoteControlButton extends Component {
    static propTypes = {
        scooterId: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
            RPC_LOCK,
            RPC_UNLOCK,
            RPC_OPEN_TRUNK,
            RPC_FIND_SCOOTER,
        ]).isRequired,
        disabled: PropTypes.bool,
        onFinished: PropTypes.func,
    };

    static defaultProps = {
        onFinished: () => {},
        disabled: false,
    };

    constructor(props) {
        super(props);

        const { type } = props;

        this.state = {
            isCalling: false,
        };

        this.attrs = typeMap[type];
    }

    handleClick = () => {
        const { dispatch, scooterId, onFinished } = this.props;
        const { action } = this.attrs;

        this.setState({
            isCalling: true,
        });

        dispatch(action(scooterId))
            .catch(() => {
                // Do something
            })
            .then(res => {
                this.setState({
                    isCalling: false,
                });

                onFinished(res);
            });
    }

    render() {
        const { icon, titleCode } = this.attrs;
        const { isCalling } = this.state;
        const { disabled } = this.props;
        const cls = classNames({
            'btn-remote-control': true,
            'calling': isCalling,
            [icon]: icon,
        });
        return (
            <div className="btn-remote-control-wrapper">
                <Button className={ cls } onClick={ this.handleClick } disabled={ disabled || isCalling }>
                    <RingSpinner size="small" />
                </Button>
                <span className="btn-title">
                    <Translate value={ titleCode } />
                </span>
            </div>
        );
    }
}

export default connect(state => ({}))(RemoteControlButton);
