import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiselectFilter from './MultiselectFilter';

class CorporatesFilter extends React.Component {
    static propTypes = {
        defaultSelected: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func.isRequired,
        list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }

    static defaultProps = {
        defaultSelected: []
    }

    render() {
        const { defaultSelected, onChange, list } = this.props;
        const corporateOptions = list.map(({ corporate_id, corporate_name }) => ({
            value: corporate_id,
            text: corporate_name,
        }));
        return (
            <MultiselectFilter
                title={ I18n.t('scooter.corporate') }
                options={ corporateOptions }
                defaultSelected={ defaultSelected }
                onChange={ onChange }
                textClass="corpText"
            />
        );
    }
}

export default CorporatesFilter;
