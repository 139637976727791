const ListHeader = [{
    name: 'plate',
    value: 'scooter.plate',
    sortable: true,
    width: 148,
}, {
    name: 'model_display_name',
    value: 'scooter.model',
    sortable: true,
    width: 105,
}, {
    name: 'is_activate',
    value: 'scooter.service',
    sortable: true,
    width: 94,
}, {
    name: 'rental_state',
    value: 'scooter.rental_state',
    sortable: true,
    width: 85,
}, {
    name: 'soc',
    value: 'scooter.battery_with_unit',
    sortable: true,
    width: 97,
}, {
    name: 'tracker_soc',
    value: 'scooter.tracker_soc_with_unit',
    sortable: true,
    width: 96,
}, {
    name: 'remaining_mileage',
    value: 'scooter.remaining_mileage_with_unit',
    sortable: true,
    width: 135,
}, {
    name: 'total_mileage',
    value: 'scooter.total_mileage_with_unit',
    sortable: true,
    width: 135,
}, {
    name: 'last_rental_end_time',
    value: 'scooter.idle',
    sortable: true,
    width: 120,
}, {
    name: 'last_connection_date',
    value: 'scooter.lct',
    sortable: true,
    width: 180,
}, {
    name: 'corporate_name',
    value: 'scooter.corporate',
    sortable: true,
    width: 180,
}];

export default ListHeader;
