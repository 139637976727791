export const PAYMENT_STATUS_FAIL = 0;
export const PAYMENT_STATUS_SUCCESS = 1;
export const PAYMENT_STATUS_UNPAID = 2;
export const PAYMENT_STATUS_PENDING = 3;

export const RENTAL_SIMPLIFIED_IDLE = 0;
export const RENTAL_SIMPLIFIED_RESERVING = 1;
export const RENTAL_SIMPLIFIED_RENTING = 2;

export const RENTAL_END = 0;
export const RENTAL_RESERVING = 1;
export const RENTAL_RIDING = 2;
export const RENTAL_PARKING = 3;
export const RENTAL_CANCEL = 4;

export const RENTAL_ACTION_TIMEOUT = -2;
export const RENTAL_ACTION_RESERVE = -1;
export const RENTAL_ACTION_CANCEL = 0;
export const RENTAL_ACTION_BEEP = 1;
export const RENTAL_ACTION_RIDE = 2;
export const RENTAL_ACTION_PARK = 3;
export const RENTAL_ACTION_OPEN_TRUNK = 4;
export const RENTAL_ACTION_RETURN = 5;

export const RENTAL_PAYMENT_METHOD_CREDIT_CARD = 0;
export const RENTAL_PAYMENT_METHOD_LINE_PAY = 1;
export const RENTAL_PAYMENT_METHOD_APPLE_PAY = 2;

export const REFUND_BY_MONEY = 0;
export const REFUND_BY_COUPON = 1;
