import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Marker, MarkerClusterer } from '@react-google-maps/api';
import {
    MODEL_B,
    MODEL_B_DOT,
    MODEL_BTG,
    MODEL_F,
    MODEL_P,
    MODEL_J,
} from 'constants/scooter';
import getModelType from 'helpers/getModelType';
import { Translate } from 'react-redux-i18n';
import Button from 'components/Form/Button';
import Location from 'components/Scooter/Location';
import { showMapContent } from 'actions';
import ImmutablePropTypes from 'react-immutable-proptypes';

import scooterClusterIcon from 'assets/map/cluster/icon-mp-scooter@3x.png';
import goStationClusterIcon from 'assets/map/cluster/icon-mp-gostation@3x.png';

import activedModelB from 'assets/map/pin-scooter-simple-active-b@3x.png';
import activedModelBdot from 'assets/map/pin-scooter-simple-active-b-dot@3x.png';
import activedModelBkoi from 'assets/map/pin-scooter-simple-primary-b-koi@3x.png';
import activedModelF from 'assets/map/pin-scooter-simple-active-f@3x.png';
import activedModelP from 'assets/map/pin-scooter-simple-active-p@3x.png';
import activedModelJ from 'assets/map/pin-scooter-simple-active-j@3x.png';

import primaryModelB from 'assets/map/pin-scooter-simple-primary-b@3x.png';
import primaryModelBdot from 'assets/map/pin-scooter-simple-primary-b-dot@3x.png';
import primaryModelBkoi from 'assets/map/pin-scooter-simple-secondary-b-koi@3x.png';
import primaryModelF from 'assets/map/pin-scooter-simple-primary-f@3x.png';
import primaryModelP from 'assets/map/pin-scooter-simple-primary-p@3x.png';
import primaryModelJ from 'assets/map/pin-scooter-simple-primary-j@3x.png';

import secondaryModelB from 'assets/map/pin-scooter-simple-secondary-b@3x.png';
import secondaryModelBdot from 'assets/map/pin-scooter-simple-secondary-b-dot@3x.png';
import secondaryModelBkoi from 'assets/map/pin-scooter-simple-active-b-koi@3x.png';
import secondaryModelF from 'assets/map/pin-scooter-simple-secondary-f@3x.png';
import secondaryModelP from 'assets/map/pin-scooter-simple-secondary-p@3x.png';
import secondaryModelJ from 'assets/map/pin-scooter-simple-secondary-j@3x.png';

import goStationSmallIcon from 'assets/map/icon-gostation-small@3x.png';
import goStationLargeIcon from 'assets/map/icon-gostation-large@3x.png';
import goStationInMaintainSmallIcon from 'assets/map/icon-gostation-small-maintain@3x.png';
import goStationInMaintainLargeIcon from 'assets/map/icon-gostation-large-maintain@3x.png';


export const MAX_ZOOM = 13;

export const getScooterIcon = ({
    model_code,
    selected,
    light,
}) => {
    const { Size } = window.google.maps || {};

    function getMarkerIcon(modelType) {
        switch (modelType) {
        case MODEL_F:
            return {
                primary: primaryModelF,
                secondary: secondaryModelF,
                actived: activedModelF,
            };
        case MODEL_B_DOT:
            return {
                primary: primaryModelBdot,
                secondary: secondaryModelBdot,
                actived: activedModelBdot,
            };
        case MODEL_BTG:
            return {
                primary: primaryModelBkoi,
                secondary: secondaryModelBkoi,
                actived: activedModelBkoi,
            };
        case MODEL_P:
            return {
                primary: primaryModelP,
                secondary: secondaryModelP,
                actived: activedModelP,
            };
        case MODEL_J:
            return {
                primary: primaryModelJ,
                secondary: secondaryModelJ,
                actived: activedModelJ,
            };
        case MODEL_B:
        default:
            return {
                primary: primaryModelB,
                secondary: secondaryModelB,
                actived: activedModelB,
            };
        }
    }
    const modelType = getModelType(model_code);
    const { primary, secondary, actived } = getMarkerIcon(modelType);

    let icon = primary;

    if (light) {
        icon = secondary;
    }

    if (selected) {
        icon = actived;
    }

    return {
        url: icon,
        scaledSize: new Size(25, 30),
    };
};

class ScooterMarkerItem extends React.PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        model_code: PropTypes.number,
        light: PropTypes.bool,
        lat: PropTypes.number,
        lng: PropTypes.number,
        selected: PropTypes.bool.isRequired,
        clusterer: PropTypes.shape(),
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        clusterer: undefined,
        light: false,
        lat: 0,
        lng: 0,
        model_code: -1,
    };

    handleSelect = () => {
        const { id, onClick, selected } = this.props;

        onClick([id], { selected: !selected });
    }

    render() {
        let { clusterer, model_code, id, lat, lng, light, selected } = this.props;

        if (!lat) {
            lat = 0;
        }

        if (!lng) {
            lng = 0;
        }

        const icon = getScooterIcon({
            model_code,
            selected,
            light,
        });

        return (
            <Marker
                key={ `scooter-marker-${ id }` }
                position={ { lat, lng } }
                clusterer={ clusterer }
                icon={ icon }
                onMouseDown={ (e) => e.domEvent.preventDefault() }
                onClick={ this.handleSelect }
                zIndex={ selected ? 10001 : 100 }
            />
        );
    }
}

export const ScooterMarker = connect((state, { id }) => ({
    selected: !!state.map.get('selectedIdMap').get(id),
}))(ScooterMarkerItem);

export class ScooterCluster extends React.PureComponent {
    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    render() {
        const { list, onClick } = this.props;
        const clusterOptions = {
            minimumClusterSize: 1,
            maxZoom: MAX_ZOOM,
            styles: [{
                textColor: '#fff',
                url: scooterClusterIcon,
                height: 52,
                width: 40,
            }]
        };

        return !list.length ? null : (
            <MarkerClusterer clusterClass="cluster" options={ clusterOptions }>
                {
                    clusterer => list.map(item => {
                        const { id } = item;

                        return (
                            <ScooterMarker
                                key={ `scooter-marker-${ id }` }
                                onClick={ onClick }
                                clusterer={ clusterer }
                                { ...item }
                            />
                        );
                    })
                }
            </MarkerClusterer>
        );
    }
};

export class GoStationMarker extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        full_battery_count: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        selected: PropTypes.bool.isRequired,
        clusterer: PropTypes.shape(),
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        clusterer: undefined,
    };

    checkIcon = (status, selected) => {
        let icon;
        if(status === 2 || status === 3) {
            icon = (selected) ? goStationInMaintainLargeIcon : goStationInMaintainSmallIcon;
        }
        else {
            icon = (selected) ? goStationLargeIcon : goStationSmallIcon;
        }
        return icon;
    };

    getLabel = (status, full_battery_count, selected) => {

        if(status === 2 || status === 3) return '';

        const { maps } = window.google;
        const symbolBattery = full_battery_count > 0 ? 'f' : '0';
        const symbolSelected = selected ? 'f' : '0';
        const color = `#${ symbolBattery }0${ symbolSelected }`;
        const text = full_battery_count > 0 ? full_battery_count?.toString() : ' ';
        return {
            color,
            text,
            anchor: new maps.Point(0, 100),
        };
    };

    render() {
        const { clusterer, id, lat, lng, onClick, selected, full_battery_count, status } = this.props;
        const { maps } = window.google;
        const icon = this.checkIcon(status, selected);
        const size = (
            selected ?
                new maps.Size(56, 64) :
                new maps.Size(32, 32)
        );

        const label = this.getLabel(status, full_battery_count, selected);

        return (
            <Marker
                key={ `gostation-marker-${ id }` }
                position={ { lat, lng } }
                clusterer={ clusterer }
                label={ label }
                icon={ {
                    url: icon,
                    scaledSize: size,
                } }
                onClick={ onClick }
                onMouseDown={ (e) => e.domEvent.preventDefault() }
            />
        );
    }
}

class GoStationCluster extends React.PureComponent {
    static propTypes = {
        list: ImmutablePropTypes.list.isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedId: undefined,
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(showMapContent());
    }

    handleClick = item => e => {
        const { dispatch, list } = this.props;
        const { selectedId } = this.state;
        const selectedVMS = list.find(({ id }) => id === item.id);
        const { lat, lng, loc_name, address } = selectedVMS;
        const updatedId = item.id === selectedId ? undefined : item.id;


        this.setState({
            selectedId: updatedId,
        });

        let el = null;

        if (updatedId) {
            el = (
                <React.Fragment>
                    <h6 className="vm-name">
                        { loc_name }
                    </h6>
                    <Location className="vm-address" location={ { lat, lng } }>
                        { address }
                    </Location>
                    <Button className="btn-close" onClick={ this.handleClose }>
                        <Translate value="close" />
                    </Button>
                </React.Fragment>
            );
        }

        dispatch(showMapContent(el));
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(showMapContent());

        this.setState({
            selectedId: undefined,
        });
    }

    render() {
        const { list } = this.props;
        const { selectedId } = this.state;
        const clusterOptions = {
            minimumClusterSize: 1,
            maxZoom: MAX_ZOOM,
            styles: [{
                textColor: '#fff',
                url: goStationClusterIcon,
                height: 52,
                width: 40,
            }]
        };

        return list.size === 0 ? null : (
            <MarkerClusterer clusterClass="cluster" options={ clusterOptions }>
                {
                    clusterer => list.map(item => {
                        const { id } = item;

                        return (
                            <GoStationMarker
                                key={ `gostation-marker-${ id }` }
                                onClick={ this.handleClick(item) }
                                selected={ id === selectedId }
                                clusterer={ clusterer }
                                { ...item }
                            />
                        );
                    })
                }
            </MarkerClusterer>
        );
    }
};

export default connect(state => ({
    list: state.map.get('vms'),
}))(GoStationCluster);
