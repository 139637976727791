import { combineReducers } from 'redux';
import general from './general';
import asyncHandler from './util/async-handler';
import storeCreater from './util/store-creater';
import scooter from './scooter';
import account from './account';
import ticket from './ticket';
import map from './map';
import geolocation from './geolocation';
import admin from './admin';
import { i18nReducer } from 'react-redux-i18n';


export default combineReducers({
    general,
    asyncHandler,
    scooter,
    account,
    ticket,
    map,
    geolocation,
    admin,
    i18n: i18nReducer,
});

export const createStore = storeCreater;
