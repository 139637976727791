import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import serialize from 'form-serialize';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import AuthFeature from 'components/AuthFeature';
import { AUTH_EDIT_TICKET } from 'constants/permission';
import { convertToUTC } from 'helpers/time-handler';
import Form from 'components/Form';
import NavigationBar from 'components/NavigationBar';
import FlexGroup from 'components/FlexGroup';
import Smart from 'components/Smart';
import TicketForm from './Form';
import {
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    STATUS_CLOSED
} from 'constants/ticket';
import {
    updateOneTicket,
    cleanOneTicket,
    toggleErrorDialog,
    toogleNavigation,
    updateDocumentTitle,
    clearVMS,
} from 'actions';
import './ticket-details.scss';

class TicketDetail extends Component {
    static propTypes = {
        params: PropTypes.shape({}).isRequired,
        opUsers: ImmutablePropTypes.list.isRequired,
        oneTicket: ImmutablePropTypes.map.isRequired,
        customAction: PropTypes.func,
        onFetch: PropTypes.func.isRequired,
        onToggle: PropTypes.func.isRequired,
        editMode: PropTypes.bool,
        children: PropTypes.node,
        waitRetrieve: PropTypes.bool,
    };

    static defaultProps = {
        customAction: () => {},
        editMode: false,
        children: null,
        waitRetrieve: true,
    };

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            updating: false,
            newDueDate: undefined,
            doOnline: [],
            newDescription: undefined,
        };
    }

    componentDidUpdate() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle(I18n.t('ticket.view_title')));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearVMS());
        dispatch(cleanOneTicket());
    }

    fetchData = () => {
        const { params, onFetch } = this.props;
        const ticketId = params.ticketId;

        return onFetch(ticketId);
    }

    getDiff = newPayload => {
        const { oneTicket } = this.props;
        const jsonOneTicket = oneTicket.toJS();
        const keys = Object.keys(newPayload);

        const diff = keys.reduce((obj, key) => {
            if (newPayload[key] !== undefined && jsonOneTicket[key] !== newPayload[key]) {
                obj[key] = newPayload[key];
            }

            return obj;
        }, {});

        return [Object.keys(diff).length > 0, diff];
    }

    optionallyGetCustomFields = (custom_fields = {}) => {
        const { oneTicket } = this.props;
        const tracker_id = oneTicket.get('tracker_id');
        const origCustomFields = oneTicket.get('custom_fields');

        if (![MAIN_TYPE_DISPATCH, MAIN_TYPE_MAINTENANCE].includes(tracker_id)) {
            return undefined;
        }

        const availableIdList = origCustomFields
            .filter(({ editable }) => editable)
            .map(({ id }) => id.toString());

        return Object.values(custom_fields).reduce((ary, { id, value }) => {
            if (availableIdList.includes(id)) {
                ary.push({
                    id: Number(id),
                    value: value ?? '',
                });
            }

            return ary;
        }, []);
    }

    handleSubmit = e => {
        const { updating } = this.state;
        if (updating) {
            return;
        }

        const { oneTicket } = this.props;
        const id = oneTicket.get('id');
        const description_editable = oneTicket.get('description_editable');
        const { dispatch } = this.props;
        const payload = serialize(e.currentTarget, { hash: true });

        const {
            tracker_subtype_id,
            assigned_to_id,
            description = description_editable ? '' : undefined,
            due_date,
            severity_id,
            status_id,
            custom_fields,
            scooter_state_action
        } = payload;
        const [needUpdate, diff] = this.getDiff({
            assigned_to_id,
            description,
            due_date: convertToUTC(due_date) || undefined,
            tracker_subtype_id: Number(tracker_subtype_id) || undefined,
            severity_id: Number(severity_id) || undefined,
            status_id: Number(status_id) || undefined,
            custom_fields: this.optionallyGetCustomFields(custom_fields)
        });

        if (diff.status_id === STATUS_CLOSED && scooter_state_action) {
            diff.scooter_state_action = Number(scooter_state_action);
        }

        if (needUpdate) {
            this.setState({
                updating: true,
            });

            dispatch(updateOneTicket(id, diff)).then(({ error }) => {
                if (!error) {
                    this.toggleEditMode();
                    return;
                }
                else {
                    this.setState({
                        updating: false,
                    });
                }

                if (error.message === 'ticket.unclosed_tickets_error_message') {
                    dispatch(toggleErrorDialog(I18n.t(error.message)));
                }
                else if (error.response?.status === 409) {
                    dispatch(toggleErrorDialog(I18n.t('ticket.please_check_scooter_error_message')));
                }
                // without auth to access this ticket
                else if (error.response?.status === 403) {
                    dispatch(cleanOneTicket());
                    dispatch(toogleNavigation());
                }
                else {
                    if (error.response?.status !== 401) {
                        dispatch(toggleErrorDialog(I18n.t('ticket.general_error_message')));
                    }
                }


                this.toggleEditMode();
            });
        }
        else {
            this.toggleEditMode();
        }
    }

    toggleEditMode = () => {
        const { onToggle } = this.props;
        this.setState({
            updating: false,
            newDueDate: undefined,
            doOnline: [],
            newDescription: undefined,
        }, () => {
            onToggle();
        });
    }

    toggleUpdating = () => {
        const { updating } = this.state;
        this.setState({
            updating: !updating,
        });
    }

    handleChange = (name, value) => {
        const newState = {};

        switch (name) {
        case 'due_date':
            newState.newDueDate = value;
            break;
        case 'do_online':
            newState.doOnline = value;
            break;
        case 'description':
            newState.newDescription = value;
            break;
        default:
            // Do nothing
        }

        this.setState({
            ...newState,
        });
    }

    renderFunctionButton(status_id) {
        const { editMode } = this.props;
        const { updating } = this.state;

        return (
            <AuthFeature requiredList={ [AUTH_EDIT_TICKET] }>
                <FlexGroup end gap>
                    { !editMode ? (
                        <Button
                            type="button"
                            color="primary"
                            onClick={ this.toggleEditMode }
                            disabled={ [STATUS_CLOSED, undefined].includes(status_id) }
                        >
                            <Translate value="ticket.edit_ticket_info" />
                        </Button>
                    ) : (
                        <React.Fragment>
                            <Button type="button" color="secondary" outline onClick={ this.toggleEditMode }>
                                <Translate value="cancel" />
                            </Button>
                            <Button type="submit" color="primary" disabled={ updating }>
                                <Translate value="save" />
                            </Button>
                        </React.Fragment>
                    ) }
                </FlexGroup>
            </AuthFeature>
        );
    }

    render() {
        const { params, customAction, editMode, children, waitRetrieve } = this.props;
        const { updating, doOnline, newDueDate, newDescription } = this.state;

        const { oneTicket, opUsers } = this.props;
        const jsonOneTicket = oneTicket.toJS();
        const {
            status_id,
            plate_no,
            tracker_id,
            tracker_subtype_id,
            due_date,
            description,
        } = jsonOneTicket;

        const ticketData = {
            ...jsonOneTicket,
            due_date: newDueDate ?? moment(due_date).format(I18n.t('datetime_format.long')),
            description: newDescription ?? description,
            doOnline,
            opUsers,
        };

        const pageTitle = (
            oneTicket.size > 0 ? [
                `#${ params.ticketId }: `,
                `[${ plate_no }]`,
                `[${ I18n.t(`ticket.main_type_${ tracker_id }`) }]`,
                `[${ I18n.t(`ticket.sub_type_${ tracker_subtype_id }`) }]`,
            ].join('') : null
        );

        const detailClass = classNames({
            'ticket-details': true,
            'fix-height': waitRetrieve || oneTicket.size === 0,
        });

        return (
            <Form
                className="ticket-form"
                onSubmit={ this.handleSubmit }
                inProgress={ updating }
                inline
            >
                <NavigationBar
                    sticky
                    title={ pageTitle }
                    customAction={ customAction }
                    className="line-clamp"
                >
                    { this.renderFunctionButton(status_id) }
                </NavigationBar>
                <div className={ detailClass }>
                    <Smart
                        className="form-container"
                        fetch={ this.fetchData }
                        pauseRefresh
                        seamless
                    >
                        <TicketForm
                            data={ ticketData }
                            editMode={ editMode }
                            updating={ updating }
                            onStartComment={ this.toggleUpdating }
                            onCompleteComment={ this.toggleUpdating }
                            onChange={ this.handleChange }
                        >
                            { children }
                        </TicketForm>
                    </Smart>
                </div>
            </Form>
        );
    };
}

export default connect(state => ({
    i18n: state.i18n,
    opUsers: state.account.get('opUsers'),
    oneTicket: state.ticket.get('oneTicket'),
}))(TicketDetail);
