import {
    RENTAL_SIMPLIFIED_IDLE,
    RENTAL_SIMPLIFIED_RESERVING,
    RENTAL_SIMPLIFIED_RENTING,
} from 'constants/rental';

const rentalGroupingState = {
    [RENTAL_SIMPLIFIED_IDLE]: '閒置',
    [RENTAL_SIMPLIFIED_RESERVING]: '預約中',
    [RENTAL_SIMPLIFIED_RENTING]: '租賃中',
};

const rental = {
    'rental_grouping_state_map': rentalGroupingState,
};

export default rental;


