import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import Button from 'components/Form/Button';
import Plate from 'components/Scooter/Plate';
import Location from 'components/Scooter/Location';
import FlexGroup from 'components/FlexGroup';

const Component = ({ selectedScooter, onClose, onAssign }) => {
    const { scooter_id, plate_no, soc, location_lng, location_lat, __isFloating } = selectedScooter || {};

    return (
        <React.Fragment>
            <Plate id={ scooter_id } plateNo={ plate_no } modal={ false } />
            {
                soc !== undefined ? (
                    <FlexGroup className="profile-battery">
                        <span className="icon-battery">{ soc }</span>
                        <Translate value="scooter.battery-wo-percent" />
                    </FlexGroup>
                ) : null
            }
            <div className="profile-location">
                <Location
                    location={ {
                        lat: location_lat,
                        lng: location_lng,
                    } }
                />
            </div>
            {
                __isFloating ? (
                    <Button color="primary" className="btn-assign-me" onClick={ onAssign }>
                        <Translate value="scooter.assign_to_me" />
                    </Button>
                ) : null
            }
            <Button className="btn-close" onClick={ onClose }>
                <Translate value="close" />
            </Button>
        </React.Fragment>
    );
};

Component.propTypes = {
    selectedScooter: PropTypes.shape(),
    onClose: PropTypes.func.isRequired,
    onAssign: PropTypes.func.isRequired,
};
Component.defaultProps = {
    selectedScooter: undefined,
};

export default Component;
