import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import AuthView from 'components/AuthView';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import FilterBar, { FILTER_ROLE, FILTER_FRANCHISEE, FILTER_ACCOUNT, FILTER_NAME } from './FilterBar';
import AdminList, { PAGE_INDEX, PAGE_SIZE, SORT } from './AdminList';
import {
    fetchFleetUsers,
    updateDocumentTitle,
} from 'actions';
import './admin.scss';

class Admin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchType: FILTER_ACCOUNT,
            searchValue: undefined,
            selectedIdList: [],
            queryPayload: {
                filter: {
                    [FILTER_NAME]: undefined,
                    [FILTER_ACCOUNT]: undefined,
                    [FILTER_ROLE]: undefined,
                    [FILTER_FRANCHISEE]: undefined
                },
                sort: {},
                page: 1,
                size: getDefaultPageSize().value,
            },
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(I18n.t(`routes.${ pathname }`)));
    }

    componentDidUpdate() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(I18n.t(`routes.${ pathname }`)));
    }

    fetchFleetUsers = () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...rest } = queryPayload;
        const params = { ...filter, ...rest };

        return dispatch(fetchFleetUsers(params));
    }

    handleChange = fetchType => ({ page, sort, size }) => {
        const { queryPayload } = this.state;
        let rest;
        switch (fetchType) {
        case PAGE_INDEX:
            rest = {
                page,
                sort: queryPayload.sort,
                size: queryPayload.size,
            };
            break;
        case PAGE_SIZE:
            rest = {
                page: 1,
                sort: queryPayload.sort,
                size,
            };
            break;
        case SORT:
            rest = {
                page: 1,
                sort,
                size: queryPayload.size,
            };
            break;
        default:
            break;
        }
        this.setState({
            selectedIdList: [],
            queryPayload: {
                ...queryPayload,
                ...rest,
            }
        }, this.fetchFleetUsers);
    }

    handleSelect = selectedIdList => {
        this.setState({ selectedIdList });
    }

    handleSearchValueChange = searchValue => {
        this.setState({
            searchValue
        });
    }

    handleFilterOn = (type, value) => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        filter = {
            ...filter,
            [type]: value,
        };

        this.setState({
            selectedIdList: [],
            queryPayload: {
                ...queryPayload,
                filter,
                page: 1,
            }
        }, this.fetchFleetUsers);
    }

    changeSearchType = e => {
        const { queryPayload, searchValue } = this.state;
        const value = e.currentTarget.value;
        const filter = { [value]: searchValue };
        queryPayload.filter = filter;
        this.setState({
            searchType: value
        });

        if (searchValue) {
            this.setState({
                selectedIdList: [],
                searchType: value,
                queryPayload: {
                    ...queryPayload,
                    filter,
                    page: 1,
                }
            }, this.fetchFleetUsers);
        }
    }

    handleSubmit = () => {
        const { searchType, searchValue, queryPayload } = this.state;
        const filter = {
            [searchType]: searchValue,
        };

        this.setState({
            selectedIdList: [],
            queryPayload: {
                ...queryPayload,
                filter,
                page: 1,
            }
        }, this.fetchFleetUsers);
    }

    render() {
        const {
            selectedIdList,
            queryPayload,
            searchType,
            searchValue,
        } = this.state;
        const { filter } = queryPayload;

        return (
            <AuthView className="admin-container">
                <FilterBar
                    defaultSearchType={ searchType }
                    searchValue={ searchValue }
                    onSearchValueChange={ this.handleSearchValueChange }
                    selected={ filter }
                    onChange={ this.handleFilterOn }
                    onChangeSearchType={ this.changeSearchType }
                    onSubmit={ this.handleSubmit }
                />
                <AdminList
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchFleetUsers }
                    onSelect={ this.handleSelect }
                    onChange={ this.handleChange }
                    selectedIdList={ selectedIdList }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(Admin);
