const datetimeFormat = {
    'date': 'YYYY-MM-DD',
    'time': 'HH:mm:ss',
    'long': 'YYYY-MM-DD HH:mm:ss',
    'utc': 'YYYY-MM-DDTHH:mm:00',
};
const translations = {
    'lang': '中文',
    'login': '登入',
    'logout': '登出',
    'cancel': '取消',
    'unknown': '不明',
    'from': '起',
    'to': '迄',
    'do_action': 'Do Action',
    'ok': '好',
    'login_failed': '登入失敗',
    'search': '搜尋',
    'edit': '編輯',
    'apply': '套用',
    'know': '我知道了',
    'warning': '警告',
    'confirm': '確認',
    'submit': '送出',
    'datetime_format': datetimeFormat,
    'total_count': '總數',
    'general_error_message': '哦喔! 好像發生問題了，請再試一次',
    'total_selection': '已選',
    'clear_all': '清除全部',
    'please_login': '請再次登入',
    'please_login_message': '為了維護您的工作安全，連線階段已過期。請重新登入。',
    'map_layer': '地圖圖層',
};

export default translations;
