import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Modal from 'components/Modal';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import FlexGroup from 'components/FlexGroup';
import { GSH } from 'constants/franchisee';
import api from 'api';
import {
    newFleetUser,
    toggleErrorDialog,
} from 'actions';
import { TYPE_SELECT, TYPE_TEXT, TYPE_EMAIL } from 'components/Form/Input/input-type';
import AuthFeature from 'components/AuthFeature';
import { AUTH_VIEW_FLEET_USER_ROLE } from 'constants/permission';
import './new-user.scss';


class NewUser extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            account: '',
            name: '',
            role_id: '',
            franchisee_id: GSH,
            userRoles: [],
        };
    }

    componentDidMount() {
        this.fetchUserRolesById();
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = async e => {
        const formData = serialize(e.currentTarget, { hash: true });
        formData.account = formData.account.toLowerCase();
        const { dispatch, onCreated } = this.props;

        try {
            const { error } = await dispatch(newFleetUser(formData));
            if (error) {
                throw error;
            }

            onCreated();
        }
        catch (error) {
            const { code } = error.response.data;
            const message = code !== undefined ?
                I18n.t(`admin.update_user_error_message_${ code }`) :
                I18n.t('general_error_message');
            dispatch(toggleErrorDialog(message));
        }
    }

    fetchUserRolesById() {
        const { franchisee_id } = this.state;
        api.fetchUserRoles({ franchisee_id: [franchisee_id] })()
            .then(({ data: userRoles }) => {
                this.setState({ userRoles });
            });
    }

    render() {
        const { account, name, role_id, userRoles } = this.state;
        const { onClose } = this.props;
        const title = I18n.t('admin.new_user');
        const roleOptions = userRoles.map(role => ({
            name: role.name,
            value: role.id,
        }));
        roleOptions.unshift({ name: '', value: '' });

        return (
            <Modal className="new-user" title={ title } onClose={ onClose }>
                <Form onSubmit={ this.handleSubmit } onChange={ this.handleChange }>
                    <Input
                        name="account"
                        type={ TYPE_EMAIL }
                        caption="admin.account"
                        value={ account }
                        required
                    />
                    <Input
                        name="name"
                        type={ TYPE_TEXT }
                        caption="admin.name"
                        value={ name }
                        required
                    />
                    <AuthFeature requiredList={ [ AUTH_VIEW_FLEET_USER_ROLE ] }>
                        <Input
                            name="role_id"
                            type={ TYPE_SELECT }
                            caption="admin.role"
                            selected={ role_id }
                            value={ roleOptions }
                            required
                        />
                    </AuthFeature>
                    <FlexGroup end gap>
                        <Button type="button" className="btn-cancel" onClick={ onClose } outline>
                            <Translate value="cancel" />
                        </Button>
                        <Button color="primary" type="submit">
                            <Translate value="submit" />
                        </Button>
                    </FlexGroup>
                </Form>
            </Modal>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(NewUser);
