import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
    RENTAL_SIMPLIFIED_IDLE,
    RENTAL_SIMPLIFIED_RESERVING,
    RENTAL_SIMPLIFIED_RENTING,
    RENTAL_END,
    RENTAL_RESERVING,
    RENTAL_RIDING,
    RENTAL_PARKING,
    RENTAL_CANCEL,
} from 'constants/rental';

export const getRentalStatusOptions = simplified => {
    const rentalStates = simplified ? [
        RENTAL_SIMPLIFIED_IDLE,
        RENTAL_SIMPLIFIED_RESERVING,
        RENTAL_SIMPLIFIED_RENTING,
    ] : [
        RENTAL_END,
        RENTAL_RESERVING,
        RENTAL_RIDING,
        RENTAL_PARKING,
        RENTAL_CANCEL,
    ];
    const translationMap = simplified ?
        'rental.rental_grouping_state_map' :
        'rental.rental_specific_state_map';
    return rentalStates.map(
        value => ({
            text: I18n.t(`${ translationMap }.${ value }`),
            value,
        })
    );
};
const Component = ({ status, className, showSpecific }) => {
    const renderProperText = () => {
        let el;
        const path = (showSpecific ? 'rental_specific_state_map' : 'rental_grouping_state_map');

        switch (status) {
        case RENTAL_END:
        case RENTAL_RESERVING:
        case RENTAL_RIDING:
        case RENTAL_PARKING:
        case RENTAL_CANCEL:
            el = <Translate value={ `rental.${ path }.${ status }` } />;
            break;
        default:
            el = '';
        }

        return el;
    };

    return (
        <div className={ className }>
            { renderProperText() }
        </div>
    );
};

Component.propTypes = {
    status: PropTypes.number,
    className: PropTypes.string,
    showSpecific: PropTypes.bool,
};

Component.defaultProps = {
    status: undefined,
    className: '',
    showSpecific: false,
};

export default Component;
