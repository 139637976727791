import {
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_SEARCH,
    LOGIN,
    ADMIN,
} from 'constants/routes';
import {
    GROUP_OPERATION,
    GROUP_ADMIN,
} from 'constants/routes-with-auth';

const routes = {
    [SCOOTER_LIST]: 'Scooter Management',
    [SCOOTER_MAP]: 'Scooter Map',
    [SCOOTER_SEARCH]: 'Scooter Finder',
    [LOGIN]: 'Login',
    [ADMIN]: 'User Management',
    [`category_${ GROUP_OPERATION }`]: 'Operation',
    [`category_${ GROUP_ADMIN }`]: 'Administrator',
};

export default routes;
