import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { getLocaleMap, defaultLanguage } from 'locale';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { setLocale } from 'react-redux-i18n';

import './locale.scss';

class Locale extends Component {
    static propTypes = {
        i18n: PropTypes.shape({}).isRequired,
        permission: ImmutablePropTypes.map,
    };
    static defaultProps = {
        permission: null,
    };

    constructor(props) {
        super(props);

        const { i18n } = props;
        this.supportLocaleMap = [];
        this.defaultSupportLocale = '';
        this.state = {
            localeKey: i18n.locale
        };
    }

    componentDidMount() {
        const { permission } = this.props;
        const localeMap = getLocaleMap();
        const { support_lang_list: langList } = permission?.toJS() ?? [];
        this.supportLocaleMap = langList ? langList.map(item => localeMap[item])
            : localeMap[defaultLanguage];
        this.defaultSupportLocale = langList ? langList[0] : defaultLanguage;
        let clientLocale = localStorage.getItem('locale');
        if (langList && langList.indexOf(clientLocale) < 0) {
            localStorage.removeItem('locale');
            clientLocale = this.defaultSupportLocale;
        }
        if (!clientLocale) {
            clientLocale = this.defaultSupportLocale;
        }
        this.changeLocale(clientLocale);
    }

    componentDidUpdate(prevProps, prevState) {
        const { i18n } = this.props;
        const { locale } = i18n;
        const { localeKey } = this.state;
        if (prevState.i18n !== i18n) {
            if (locale !== localeKey) {
                this.setState({
                    localeKey: locale
                });
            }
        }
    }

    changeLocale = locale => {
        const { dispatch } = this.props;
        this.locale = locale;
        localStorage.setItem('locale', this.locale);
        dispatch(setLocale(this.locale));
    }

    handleLocaleChange = locale => {
        this.setState({ localeKey: locale },
            this.changeLocale(locale)
        );
    };

    renderLangButtons = () => {
        const { localeKey } = this.state;
        const { i18n } = this.props;
        const { translations } = i18n;
        const length = this.supportLocaleMap.length;
        const langButtons = [];
        if (length !== 0) {
            for (let i = 0; i < length; i++) {
                const locale = this.supportLocaleMap[i]?.locale;
                if (locale) {
                    const buttonClassName = classNames({
                        'lang-button': true,
                        'active': localeKey === locale,
                    });
                    langButtons.push(
                        <Button
                            key={ locale }
                            className={ buttonClassName }
                            onClick={ () => this.handleLocaleChange(locale) }
                        >
                            { translations[locale].lang }
                        </Button>
                    );
                    if (i !== length - 1) {
                        langButtons.push(' ｜ ');
                    }
                }
                else {
                    langButtons.pop();
                }
            }
        }
        return langButtons;
    }

    render() {
        return (
            <div className="lang-buttons">{ this.renderLangButtons() }</div>
        );
    };
};

export default connect(state => ({
    i18n: state.i18n,
    permission: state.account.get('permission'),
}))(Locale);
