import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card, { CardItem } from 'components/Profile/Card';
import BatteryCapacity from 'components/BatteryCapacity';
import RentalState from 'components/Scooter/RentalState';
import ScooterStatus from 'components/Scooter/ScooterStatus';
import TrunkStatus from 'components/Scooter/TrunkStatus';
import Location from 'components/Scooter/Location';
import ForceReturn from 'components/Scooter/ForceReturn';
import Datetime from 'components/Datetime';
import ActionPanel from 'components/ActionPanel';
import GoogleMap, { VIP_READ } from 'components/Map';
import FlexGroup from 'components/FlexGroup';
import { Button } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { convertDistance } from 'helpers/util';
import { clearSingleScooter } from 'actions';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
} from 'components/Map/Toolbox';
import { Map } from 'immutable';
import {
    RENTAL_RIDING,
} from 'constants/rental';
import { BATTERY_ERROR_204, BATTERY_ERROR_238 } from 'constants/scooter';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';
import {
    fetchSingleScooter,
} from 'actions';
import './scooter-profile.scss';
import addServiceZone from "components/Map/Layers/service-zone";

class Profile extends Component {
    static propTypes = {
        item: PropTypes.shape({
            scooter_id: PropTypes.string,
            plate: PropTypes.string,
            gtu_fw_version: PropTypes.string,
            gtu_imei: PropTypes.number,
            is_activate: PropTypes.bool,
            last_connection_date: PropTypes.string,
            query_date_time: PropTypes.string,
            rental_state: PropTypes.number,
            scooter_fw_version: PropTypes.string,
            scooter_state: PropTypes.number,
            soc1: PropTypes.number,
            soc2: PropTypes.number,
            tag: PropTypes.number,
            total_mileage: PropTypes.number,
            trunk_state: PropTypes.number,
            current_location: PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number,
            }),
            tracker_soc: PropTypes.number,
            csq: PropTypes.number,
            satellites: PropTypes.number,
        }),
    };

    static defaultProps = {
        item: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            hiddenActionPanel: true,
            map: undefined,
            maps: undefined,
        };
        this.marker = undefined;
        this.vipLayer = VIP_READ;
    }

    componentDidUpdate(prevProps, prevState) {
        const { map } = this.state;
        const { item } = this.props;
        const { corporate_id } = item;
        if (map && prevState.map !== map) {
            addServiceZone({ map, params: { corporate_id }, vipLayer: this.vipLayer });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const stateChanged = !Map(nextState).equals(Map(this.state));
        const { item } = this.props;
        const { current_location: nextLoc, ...nextRestProps } = nextProps.item;
        const { current_location: loc, ...restProps } = item;
        const propsChanged = !(Map(nextRestProps).equals(Map(restProps)) && Map(nextLoc).equals(Map(loc)));
        return  stateChanged || propsChanged;
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
    }

    toggleClose = () => {
        const { hiddenActionPanel } = this.state;
        this.setState({
            hiddenActionPanel: !hiddenActionPanel,
        });
    }

    toggleNewTicket = () => {
        const { createTicket } = this.state;
        this.setState({
            createTicket: !createTicket,
        });
    }

    afterNewTicket = () => {
        const { dispatch, item } = this.props;
        const { scooter_id } = item;
        this.toggleNewTicket();

        return dispatch(fetchSingleScooter(scooter_id));
    }

    handleGoogleApi = ({ map, maps }) => {
        this.setState({
            map,
            maps
        });
    }

    renderCSQ = (csq) => {
        if (csq) {
            if (csq >= 20 && csq <= 31) {
                return `${csq} (${I18n.t('scooter.excellent')})`;
            }
            else if (csq >= 15 && csq <= 19) {
                return `${csq} (${I18n.t('scooter.good')})`;
            }
            else if (csq >= 10 && csq <= 14) {
                return `${csq} (${I18n.t('scooter.ok')})`;
            }
            else if (csq >= 5 && csq <= 10) {
                return `${csq} (${I18n.t('scooter.marginal')})`;
            }
            else if (csq < 5) {
                return `${csq} (${I18n.t('scooter.poor')})`;
            }
            else if (csq === 99) {
                return `${csq} (${I18n.t('scooter.no_signal_detectable')})`;
            }
            else {
                return csq;
            }
        }
        else {
            return '--';
        }
    }

    renderSatellite = (satellite) => {
        let satelliteValue = '--';
        if (satellite >= 3) {
            satelliteValue = `${satellite} (${I18n.t('scooter.satellitesHigh')})`;
        }
        else {
            satelliteValue = `${ satellite ? satellite : 0 } (${I18n.t('scooter.satellitesLow')})`;
        }
        return satelliteValue;
    }

    render() {
        const { item, children } = this.props;
        const {
            scooter_id,
            plate,
            soc,
            is_activate,
            rental_state,
            total_mileage,
            scooter_state,
            trunk_state,
            last_connection_date,
            current_location,
            tracker_soc,
            remaining_mileage,
            csq,
            satellites,
            model_display_name,
            corporate_name
        } = item;
        const { hiddenActionPanel } = this.state;
        const toolboxList = getAuthedToolBoxList([CENTER_TO_CURRENT_LOCATION, VMS]);

        return (
            <article className="scooter-profile">
                <div className="info-wrapper">
                    <Card title={ plate } className="title">
                        <div className="detail-info">
                            <CardItem name="scooter.rental_state">
                                <RentalState className="profile-item" status={ rental_state } />
                            </CardItem>
                            <CardItem name="scooter.total_mileage">
                                <PlainDetail>
                                    { total_mileage !== undefined && total_mileage !== null ?
                                        convertDistance(total_mileage) : '--' }
                                </PlainDetail>
                            </CardItem>
                            <CardItem name="scooter.model">
                                <PlainDetail>
                                    { model_display_name }
                                </PlainDetail>
                            </CardItem>
                            <CardItem name="scooter.battery">
                                {
                                    soc === BATTERY_ERROR_204 ||
                                    soc === BATTERY_ERROR_238 ?
                                        (
                                            <PlainDetail>
                                                { '--' }
                                            </PlainDetail>
                                        ) : (
                                            <BatteryCapacity
                                                className="profile-item"
                                                capacity={ soc }
                                            />
                                        )
                                }
                            </CardItem>
                            <CardItem name="scooter.activity">
                                <PlainDetail>
                                    { is_activate !== undefined ? (
                                        <Translate value={ `scooter.activation.${ is_activate ? 'on' : 'off' }` } />
                                    ) : undefined }
                                </PlainDetail>
                            </CardItem>
                            <CardItem name="scooter.remaining_mileage">
                                <PlainDetail>
                                    { remaining_mileage !== undefined && remaining_mileage !== null
                                        ? convertDistance(remaining_mileage) : '--' }
                                </PlainDetail>
                            </CardItem>
                            <CardItem name="scooter.tracker_soc">
                                <BatteryCapacity className="profile-item" capacity={ tracker_soc } />
                            </CardItem>
                            <CardItem name="scooter.scooter_state">
                                <ScooterStatus className="profile-item" status={ scooter_state } />
                            </CardItem>
                            <CardItem name="scooter.csq">
                                <PlainDetail>{ this.renderCSQ(csq) }</PlainDetail>
                            </CardItem>
                            <CardItem name="scooter.location">
                                <Location className="profile-item" location={ current_location } />
                            </CardItem>
                            <CardItem name="scooter.satellites">
                                <PlainDetail>{ this.renderSatellite(satellites) }</PlainDetail>
                            </CardItem>
                            <CardItem name="scooter.last_connection_date">
                                <Datetime
                                    className="profile-item"
                                    time={ last_connection_date }
                                    format={ I18n.t('datetime_format.long') }
                                />
                            </CardItem>
                            <CardItem name="scooter.trunk_state">
                                <TrunkStatus className="profile-item" status={ trunk_state } />
                            </CardItem>
                            <CardItem name="scooter.corporate">
                                <PlainDetail>{ corporate_name }</PlainDetail>
                            </CardItem>
                        </div>
                    </Card>

                    <FlexGroup className="button-group" gap start>
                        <Button color="primary" onClick={ this.toggleClose } disabled={ !scooter_id }>
                            <Translate value="do_action" />
                        </Button>
                        <ForceReturn id={ scooter_id } disabled={ RENTAL_RIDING !== rental_state } />
                    </FlexGroup>

                    <section className="scooter-location">
                        { scooter_id !== undefined && (
                            <GoogleMap
                                onInternalAPI={ this.handleGoogleApi }
                                toolbox={ toolboxList }
                                center={ {
                                    lat: current_location.lat,
                                    lng: current_location.lng,
                                } }
                                list={ [{
                                    id: scooter_id,
                                    lat: current_location?.lat,
                                    lng: current_location?.lng,
                                    ...item,
                                }] }
                                currentLocation
                                withoutScooterCluster
                                isDetail
                                vipLayer={ this.vipLayer }
                            />
                        ) }
                    </section>
                </div>

                { children }

                {
                    !hiddenActionPanel ?
                        (
                            <ActionPanel
                                scooterIdList={ [scooter_id] }
                                onDidAction={ this.toggleClose }
                                onClose={ this.toggleClose }
                            />
                        ) :
                        null
                }
            </article>
        );
    }
}

export default connect((state, ownProps) => ({
    scooterRelatedTickets: state.scooter.get('relatedTickets')[ownProps.item.scooter_id],
}))(Profile);

const PlainDetail = ({ children }) => <div className="profile-item">{ children }</div>;
export const IntegerDetail = ({ children }) => (
    <PlainDetail>
        { Number.isInteger(children) ? children : '--' }
    </PlainDetail>)
;
