import { createFleetApiInstanceWithToken } from '../util';

const scooter = {
    fetchScooterList: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/scooters', { params });
    },

    fetchScooterModels: () => {
        return createFleetApiInstanceWithToken().get('/scooters/models');
    },

    fetchSingleScooter: scooterId => {
        return createFleetApiInstanceWithToken().get(`/scooters/${ scooterId }`);
    },

    fetchScooterHistory: (scooterId, params = {}) => {
        return createFleetApiInstanceWithToken().get(`/scooters/${ scooterId }/histories`, { params });
    },

    remoteCommandScooter: (scooterId, params) => {
        return createFleetApiInstanceWithToken().post(`/scooters/${ scooterId }/remote_cmds`, params);
    },

    setScooterServiceState: params => {
        return createFleetApiInstanceWithToken().patch('/scooters/service_state', params);
    },

    sendRemoteCommand: (scooterId, command) => {
        return createFleetApiInstanceWithToken().post(
            `/scooters/${ scooterId }/remote_cmds`,
            { action: command }
        );
    },

    updateServiceState: list => {
        return createFleetApiInstanceWithToken().patch('/scooters/service_state', list);
    },

    forceReturn: (scooterId, params) => {
        return createFleetApiInstanceWithToken().post(`/scooters/${ scooterId }/force_return`, params);
    },

    replaceGTU: (scooterId, params) => {
        return createFleetApiInstanceWithToken().post(`/scooters/${ scooterId }/gtus`, params);
    },

    fetchGTUList: (params) => {
        return createFleetApiInstanceWithToken().get('/gtus', { params });
    },

    fetchNearbyScooters: ({ lat, lng }) => {
        const params =  {
            lat: lat || '',
            lng: lng || '',
            filter_suggest_swap_floating: true,
        };
        return createFleetApiInstanceWithToken().get('/scooters/finder', { params });
    },

    fetchScooterListByFinder: (params) => {
        return createFleetApiInstanceWithToken().get('/scooters/finder', { params });
    },

    fetchAllScooters: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/maps/scooters', { params });
    },

    firmwareVersion: (scooterId) => {
        return createFleetApiInstanceWithToken().get(`/scooters/${ scooterId }/firmware_version`);
    },

    fetchCorporatesList: () => {
        return createFleetApiInstanceWithToken().get('/corporates');
    }
};

export default scooter;
