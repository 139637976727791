import { createFleetApiInstanceWithToken } from '../util';

const map = {
    fetchZones: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/zones', { params });
    },

    fetchVMS: acceptLang => {
        return createFleetApiInstanceWithToken({
            'Accept-Language': acceptLang,
        }).get('/vms');
    },

};

export default map;
