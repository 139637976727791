import {
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_SEARCH,
    LOGIN,
    ADMIN
} from 'constants/routes';
import {
    GROUP_OPERATION,
    GROUP_ADMIN,
} from 'constants/routes-with-auth';

const routes = {
    [SCOOTER_LIST]: '車輛管理',
    [SCOOTER_MAP]: '車輛地圖',
    [SCOOTER_SEARCH]: '車輛搜尋',
    [LOGIN]: '登入',
    [ADMIN]: '使用者管理',
    [`category_${ GROUP_OPERATION }`]: '營運',
    [`category_${ GROUP_ADMIN }`]: '管理員',
};

export default routes;
