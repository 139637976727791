// status
export const STATUS_DISABLED = 0;
export const STATUS_DRAFT = 1;
export const STATUS_PUBLISHED = 2;
export const STATUS_LIVED = 3;
export const STATUS_EXPIRED = 4;

export const STATUS_GROUP = [
    STATUS_DISABLED,
    STATUS_DRAFT,
    STATUS_PUBLISHED,
    STATUS_LIVED,
    STATUS_EXPIRED,
];

// criteria type
export const TRIP_COUNT = 0;
export const TRIP_DISTANCE = 1;
export const BATTERY_SWAP_COUNT = 2;

export const CRITERIA_TYPE_GROUP = [
    TRIP_COUNT,
    TRIP_DISTANCE,
    BATTERY_SWAP_COUNT,
];

// launch mode
export const IN_APP_WEBVIEW = 0;
export const OS_DEFAULT_WEB_BROWSER = 1;

export const URL_LAUNCH_MODE_GROUP = [
    IN_APP_WEBVIEW,
    OS_DEFAULT_WEB_BROWSER,
];

// error code
export const TITLE_NOT_VALID = 40001;
export const WEB_URL_NOT_VALID = 40002;
export const URL_LAUNCH_MODE_NOT_VALID = 40003;
export const CRITERIA_TYPE_NOT_VALID = 40004;
export const CRITERIA_VALUE_NOT_VALID = 40005;
export const VALID_FROM_TO_NOT_VALID = 40006;
export const REWARD_COUPON_ID_NOT_VALID = 40007;
export const CANNOT_CHANGE_STATUS = 40010;
export const STATUS_IS_PUBLISHED = 40011;
