import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ToolButton } from './index';
import addServiceZone from '../Layers/service-zone';

const VipLayer = ({ map, corporateId, corporate }) => {
    const [enabled] = useState(false);
    const [openVip, setOpenVip] = useState(false);
    const [selectedLayer, setSelectedLayer] = useState([corporateId]);

    useEffect(() => {
        if (map) {
            if (selectedLayer.length === 0) {
                map.data.forEach((feature) => {
                    map.data.remove(feature);
                });
            }
            else {
                addServiceZone({ map, params: { corporate_id: selectedLayer } });
            }
        }
    }, [selectedLayer, map]);

    const buttonIconStyle = classNames({
        'btn-vip': true,
        'enabled': enabled,
    });

    const panelStyle = classNames({
        'vip-panel': true,
        'hide': !openVip
    });

    const toggleVipLayer = () => {
        setOpenVip(!openVip);
    };

    const handleChange = ({ corporate_id }) => (e) => {
        if (e.currentTarget.checked) {
            setSelectedLayer([
                ...selectedLayer,
                corporate_id
            ]);
        }
        else {
            const list = selectedLayer.filter((item) => item !== corporate_id);
            setSelectedLayer(list);
        }
    };

    const renderVipOptions = () => {
        const corporateOptions = corporate.toJS();
        return corporateOptions.map(({ corporate_id, corporate_name }) => (
            <Label className="filter-option" key={ corporate_id }>
                <Input
                    type="checkbox"
                    name={ corporate_name }
                    value={ corporate_id }
                    defaultChecked={ corporateId === corporate_id }
                    onChange={ handleChange({ corporate_id }) }
                />
                <div className="vip-text">{ corporate_name }</div>
            </Label>
        ) )
    }

    return (
        <div>
            <ToolButton className={ buttonIconStyle } onClick={ toggleVipLayer }>
                <div />
            </ToolButton>
            <div className={ panelStyle }>
                <Translate className="vip-title" value="map_layer" />
                <hr />
                <div className="vip-item">
                    { renderVipOptions() }
                </div>
            </div>
        </div>
    )
}

VipLayer.propTypes = {
    map: PropTypes.shape(),
    corporateId: PropTypes.string.isRequired,
    corporate: ImmutablePropTypes.list.isRequired,
};

VipLayer.defaultProps = {
    map: undefined,
}

export default connect(state => ({
    locale: state.i18n.locale,
    corporate: state.scooter.get('corporate'),
}))(VipLayer);
