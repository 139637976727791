const datetimeFormat = {
    'date': 'YYYY-MM-DD',
    'time': 'HH:mm:ss',
    'long': 'YYYY-MM-DD HH:mm:ss',
    'utc': 'YYYY-MM-DDTHH:mm:00',
};
const translations = {
    'lang': 'English',
    'login': 'Login',
    'logout': 'Logout',
    'cancel': 'Cancel',
    'unknown': 'Unknown',
    'from': 'From',
    'to': 'To',
    'do_action': 'Do Action',
    'ok': 'OK',
    'login_failed': 'Login Failed',
    'search': 'Search',
    'edit': 'Edit',
    'apply': 'Apply',
    'know': 'Yes, I know',
    'warning': 'Warning',
    'confirm': 'Confirm',
    'submit': 'Submit',
    'datetime_format': datetimeFormat,
    'total_count': 'Count',
    'general_error_message': 'Oops! Something went wrong. Please try again.',
    'total_selection': 'Selection',
    'clear_all': 'Clear All',
    'please_login': 'Please log in again',
    'please_login_message': (
        'To keep your work secure, your session has timed out. ' +
        'Please log in again.'
    ),
    'map_layer': 'Map Layer',
};

export default translations;
