export const thousandsSeparator = number => {
    return (number || 0).toString().replace(/(\d{1,3})(?=(\d{3})+$)/g, '$1,');
};

export const convertDistance = (distance = 0, withUnit = true) => {
    const [km, m] = Math.abs(distance).toString().split('.');
    const beautifulKM = thousandsSeparator(km);
    const metersWithPoint = m ? `.${ m }` : '';
    const unit = withUnit ? 'km' : '';
    return `${ beautifulKM }${ metersWithPoint } ${unit}`;
};

// duration = seconds
export const convertDuration = (duration = 0) => {
    const hr = Math.floor(duration / 3600);
    const min = Math.floor(duration / 60 % 60);
    const sec = duration % 60;
    let formatDuration = [];

    if (hr) {
        formatDuration.push(`${ hr } hrs`);
    }

    if (min) {
        formatDuration.push(`${ min } mins`);
    }

    if (formatDuration.length === 0 || sec) {
        formatDuration.push(`${ sec } sec`);
    }

    return formatDuration.join(' ');
};

export const exportCSV = (name, title, convertArray, joinTag = ',\n') => {
    let csv = title;
    csv += convertArray.join(joinTag);
    exportFile({
        name,
        source: 'data:text/csv;charset=utf-8,' + encodeURI(csv),
    });
};

export function exportFile({ source, name }) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = source;
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
}

export const empty = obj => Object.keys(obj ?? {}).length === 0;
