export const LOG_FULL_REFUND = 0;
export const LOG_COUPON_REFUND = 1;
export const LOG_CHANGE_AMOUNT = 2;
export const LOG_PARTIAL_REFUND = 3;
export const LOG_FORCE_RETURN = 4;
export const LOG_SEND_COMPENSATION = 5;
export const LOG_PURCHASE_REFUND = 6;
export const ALL_RENTAL_LOG_TYPE = [
    LOG_FULL_REFUND,
    LOG_COUPON_REFUND,
    LOG_CHANGE_AMOUNT,
    LOG_PARTIAL_REFUND,
    LOG_FORCE_RETURN,
    LOG_SEND_COMPENSATION,
    LOG_PURCHASE_REFUND
];
