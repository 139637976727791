import {
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_PROFILE,
    SCOOTER_SEARCH,
    ADMIN,
} from 'constants/routes';
import {
    AUTH_VIEW_SCOOTER_LIST,
    AUTH_VIEW_SCOOTER_FINDER,
    AUTH_VIEW_SCOOTER_DETAIL,
    AUTH_VIEW_FLEET_USER_LIST,
} from 'constants/permission';

export const AUTH_BY_PASS = 'by_pass';
export const GROUP_DASHBOARD = 'dashboard';
export const GROUP_CUSTOMER = 'customer_service';
export const GROUP_MARKETING = 'marketing';
export const GROUP_OPERATION = 'operation';
export const GROUP_AUDIT = 'audit';
export const GROUP_TICKET = 'ticket';
export const GROUP_ADMIN = 'admin';
export const GROUP_PROMOTION = 'promotion';
export const GROUP_TRAFFIC = 'traffic';
export const GROUP_TV = 'tv';

export const sortedGroupList = [
    GROUP_DASHBOARD,
    GROUP_TV,
    GROUP_CUSTOMER,
    GROUP_OPERATION,
    GROUP_TICKET,
    GROUP_MARKETING,
    GROUP_AUDIT,
    GROUP_PROMOTION,
    GROUP_ADMIN,
    GROUP_TRAFFIC,
];

const routesWithAuth = [{
    route: SCOOTER_LIST,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_LIST,
    isMenuItem: true,
    order: 0,
}, {
    route: SCOOTER_MAP,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_LIST,
    isMenuItem: true,
    order: 1,
}, {
    route: SCOOTER_SEARCH,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_FINDER,
    isMenuItem: true,
    order: 2,
}, {
    route: SCOOTER_PROFILE,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_DETAIL,
    isMenuItem: false,
}, {
    route: ADMIN,
    auth: AUTH_VIEW_FLEET_USER_LIST,
    category: GROUP_ADMIN,
    isMenuItem: true,
    order: 1,
}];

export default routesWithAuth;
