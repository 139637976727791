import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Form/Input';

import './search-field.scss';

class SearchField extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
        type: PropTypes.string,
        onSubmit: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.node,
        tag: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string,
            PropTypes.func,
        ]),
        autoFocus: PropTypes.bool,
        stopTriggerByEnter: PropTypes.bool,
        inputRef: PropTypes.shape({}),
    };

    static defaultProps = {
        placeholder: '',
        type: 'text',
        onSubmit: () => {},
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {},
        value: undefined,
        tag: 'form',
        autoFocus: false,
        stopTriggerByEnter: false,
        inputRef: undefined,
    };

    constructor(props) {
        super(props);

        const { inputRef } = props;
        this.elWrapper = React.createRef();
        this.elInput = inputRef || React.createRef();
    }

    handleClick = e => {
        const { stopTriggerByEnter, tag } = this.props;

        if (stopTriggerByEnter) {
            e.preventDefault();
        }

        if (tag !== 'form') {
            this.sendRequest();
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        this.sendRequest();
    }

    sendRequest = () => {
        const { onSubmit } = this.props;
        onSubmit(this.elInput.current.value);
    }

    handleChange = e => {
        e.preventDefault();
        const { onChange } = this.props;

        onChange(e.currentTarget.value);
    }

    handleKeyDown = e => {
        const { stopTriggerByEnter } = this.props;
        let stop = (stopTriggerByEnter && e.keyCode === 13);

        if (stop) {
            e.preventDefault();
        }

        return !stop;
    }

    // prevent safari bug which is input can't focus
    handleInputClick(e) {
        e.preventDefault();
    }

    render() {
        const {
            value,
            placeholder,
            type,
            onFocus,
            onBlur,
            tag,
            autoFocus,
        } = this.props;
        const Wrapper = tag;

        return (
            <Wrapper ref={ this.elWrapper } className="search-field" onSubmit={ this.handleSubmit }>
                <Input
                    innerRef={ this.elInput }
                    type={ type }
                    name="search-text"
                    placeholder={ placeholder }
                    autoComplete="off"
                    onClick={ this.handleInputClick }
                    onChange={ this.handleChange }
                    onFocus={ onFocus }
                    onBlur={ onBlur }
                    onKeyDown={ this.handleKeyDown }
                    value={ value }
                    autoFocus={ autoFocus }
                />
                <button type="submit" className="go-search" onClick={ this.handleClick }>
                    <span className="fas fa-search" />
                </button>
            </Wrapper>
        );
    }
}

export default SearchField;
