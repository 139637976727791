import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import preferenceHandler from 'helpers/preference-handler';
import './page-size-select.scss';

const PAGE_SIZE_KEY = 'page-size';
const pageSizeOptions = [
    { value: 50, label: 50 },
    { value: 100, label: 100, idDefault: true },
    { value: 200, label: 200 },
];

class PageSizeSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedPageSize: getDefaultPageSize(),
        };
    }

    handleChange = option => {
        const { onChange } = this.props;
        preferenceHandler.set(PAGE_SIZE_KEY, option);

        this.setState({
            selectedPageSize: option,
        });

        onChange(option);
    }

    render() {
        const { selectedPageSize } = this.state;

        return (
            <div className="page-size-select-wrapper">
                <Select
                    className="page-size-select"
                    options={ pageSizeOptions }
                    value={ selectedPageSize }
                    onChange={ this.handleChange }
                />
            </div>
        );
    }
}

export default PageSizeSelect;

export const getDefaultPageSize = () => {
    const preferPageSize = preferenceHandler.get(PAGE_SIZE_KEY);
    return preferPageSize || pageSizeOptions.find(({ idDefault }) => idDefault);
};
