/* Define the routes */
const { PUBLIC_URL } = process.env;

export const ROOT = PUBLIC_URL + '/' || '/';
export const SCOOTER_LIST = `${ ROOT }scooter/`;
export const SCOOTER_MAP = `${ ROOT }scooter-map/`;
export const SCOOTER_PROFILE = `${ ROOT }scooter/:scooterId`;
export const SCOOTER_HISTORY = `${ ROOT }scooter/:scooterId/history`;
export const SCOOTER_SEARCH = `${ ROOT }scooter/search`;
export const LOGIN = `${ ROOT }login`;
export const ADMIN = `${ ROOT }admin/`;
