import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ROOT, LOGIN } from 'constants/routes';
import AuthRouteList from 'constants/routes-with-auth';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-151169904-1', {
    testMode: ['innov', 'development', 'test'].indexOf(process.env.NODE_ENV) > -1,
    debug: process.env.NODE_ENV === 'innov' || process.env.NODE_ENV === 'development',
    gaOptions: {}
});
ReactGA.set({
    campaignName: process.env.NODE_ENV,
    campaignSource: '(direct)',
    campaignMedium: process.env.NODE_ENV,
});
class AuthView extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        permission: ImmutablePropTypes.map,
        checkPermission: PropTypes.bool,
        className: PropTypes.string,
        tag: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.node,
        ]),
    }

    static defaultProps = {
        className: '',
        permission: null,
        checkPermission: true,
        tag: 'div',
    }

    componentDidMount() {
        const { match } = this.props;
        ReactGA.pageview(match.path);
    }

    hadPermission = () => {
        const { match, isLoggedIn, permission } = this.props;
        let routes = [];

        if (isLoggedIn && permission) {
            const { authorities } = permission.toJS();
            routes = AuthRouteList.filter(({ auth }) => {
                return authorities.map(({ authority }) => authority).indexOf(auth) > -1;
            });
        }

        // DO NOT DELETE: it will always pass while testing.
        return process.env.NODE_ENV === 'test' || routes.findIndex(({ route }) => route === match.path) > -1;
    }

    render() {
        const { children, className, history, isLoggedIn, permission, checkPermission, tag } = this.props;
        const shouldHavePermission = (isLoggedIn && !permission);
        const pathName = history.location.pathname;
        const accessAllowed = this.hadPermission();
        const CustomTag = tag;
        let redirectEl;
        let el;

        if (!isLoggedIn) {
            const redirectTo = (pathName ? `?redirectTo=${ encodeURIComponent(pathName) }` : '');
            redirectEl = <Redirect to={ `${ LOGIN }${ redirectTo }` } />;
        }

        if (checkPermission && isLoggedIn && !accessAllowed) {
            redirectEl = <Redirect to={ ROOT } />;
        }

        el = (redirectEl ? redirectEl : children);

        // wait for getting permission
        if (shouldHavePermission) {
            el = <span />;
        }

        return (
            <CustomTag className={ className }>
                { el }
            </CustomTag>
        );
    }
}

export default withRouter(connect(state => ({
    isLoggedIn: state.account.get('isLoggedIn'),
    permission: state.account.get('permission'),
    profile: state.account.get('profile'),
}))(AuthView));
