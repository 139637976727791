import React from 'react';
import Button from 'components/Form/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './toolbox.scss';

export const FULL_SCREEN = 'FULLSCREEN';
export const CENTER_TO_CURRENT_LOCATION = 'CENTER_TO_CURRENT_LOCATION';
export const VMS = 'VMS';
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT';
export const TOP_RIGHT = 'TOP_RIGHT';
export const TOP_LEFT = 'TOP_LEFT';
export const VIP_LAYER_POSITION = 'VIP_LAYER_POSITION';
export const VIP_LAYER_POSITION_TOP = 'VIP_LAYER_POSITION_TOP';

const Component = ({ position, children }) => {
    const cls = classNames({
        toolbox: true,
        'vip-layer': position === VIP_LAYER_POSITION,
        'vip-layer-top': position === VIP_LAYER_POSITION_TOP,
        'top-right': position === TOP_RIGHT,
        'top-left': position === TOP_LEFT,
        'bottom-right': position === BOTTOM_RIGHT,
    });

    return (
        <aside className={ cls }>
            { children }
        </aside>
    );
};
Component.propTypes = {
    position: PropTypes.oneOf([TOP_RIGHT, TOP_LEFT, BOTTOM_RIGHT, VIP_LAYER_POSITION, VIP_LAYER_POSITION_TOP]),
};
Component.defaultProps = {
    position: TOP_RIGHT,
};


export default Component;

const ToolButton = ({ children, className, ...props }) => {
    const cls = classNames({
        'map-toolbox-btn': true,
        [className]: !!className,
    });
    return (
        <Button className={ cls } color="info" type="button" { ...props } >
            { children }
        </Button>
    );
};

export {
    ToolButton,
};
