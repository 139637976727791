import {
    RENTAL_SIMPLIFIED_IDLE,
    RENTAL_SIMPLIFIED_RESERVING,
    RENTAL_SIMPLIFIED_RENTING,
} from 'constants/rental';
const rentalGroupingState = {
    [RENTAL_SIMPLIFIED_IDLE]: 'Idle',
    [RENTAL_SIMPLIFIED_RESERVING]: 'Reserved',
    [RENTAL_SIMPLIFIED_RENTING]: 'In Rental',
};

const rental = {
    'rental_grouping_state_map': rentalGroupingState,
};

export default rental;
