import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './battery-capacity.scss';

class BatteryCapacity extends Component {
    static propTypes = {
        capacity: PropTypes.number,
        hasPercentage: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        capacity: undefined,
        className: '',
        hasPercentage: true,
    };

    render() {
        const { capacity, className, hasPercentage } = this.props;
        const styles = classNames({
            'battery-capacity': true,
            'has-percentage': hasPercentage,
            [className]: true,
        });
        return (capacity !== null && !isNaN(capacity)) ?
            (
                <div className={ styles }>
                    { capacity }
                </div>
            ) : null;
    }
}

export default BatteryCapacity;
