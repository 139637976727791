import React from 'react';
import App from 'view/App';
import Welcome from 'view/Welcome';
import ScooterList from 'view/ScooterList';
import ScooterMap from 'view/ScooterMap';
import ScooterHistory from 'view/ScooterHistory';
import ScooterSearch from 'view/ScooterSearch';
import TicketSystem from 'view/TicketSystem';
import Login from 'view/Login';
import Admin from 'view/Admin';

import {
    ROOT,
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_PROFILE,
    SCOOTER_HISTORY,
    SCOOTER_SEARCH,
    LOGIN,
    ADMIN,
} from 'constants/routes';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store from 'store';
import { LoadScript } from '@react-google-maps/api';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const NoMatch = () => <h1>Not Found</h1>;
const history = createBrowserHistory();
const libraries = ['drawing', 'geometry', 'places'];
const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

export default (
    <Router>
        <HelmetProvider>
            <Provider store={ store }>
                <LoadScript
                    id="map-loader"
                    googleMapsApiKey={ REACT_APP_GOOGLE_MAP_API_KEY }
                    libraries={ libraries }
                >
                    <App history={ history }>
                        <Switch>
                            <Route path={ ROOT } exact component={ Welcome } />
                            <Route path={ SCOOTER_LIST } exact component={ ScooterList } />
                            <Route path={ SCOOTER_MAP } exact component={ ScooterMap } />
                            <Route path={ SCOOTER_SEARCH } exact component={ ScooterSearch } />
                            <Route path={ SCOOTER_PROFILE } exact component={ TicketSystem } />
                            <Route path={ SCOOTER_HISTORY } exact component={ ScooterHistory } />
                            <Route path={ ADMIN } exact component={ Admin } />
                            <Route path={ LOGIN } exact component={ Login } />
                            <Route component={ NoMatch } />
                        </Switch>
                    </App>
                </LoadScript>
            </Provider>
        </HelmetProvider>
    </Router>
);
