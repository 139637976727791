import {
    EMAIL_FORMAT_INVALID,
    STATUS_INVALID,
    ROLE_ID_INVALID,
    ACCOUNT_ALREADY_EXISTS,
} from 'constants/admin';

const admin = {
    account: '使用者帳號',
    name: '姓名',
    role_name: '角色',
    new_user: '新增',
    remove: '移除',
    role: '角色',
    update_user: '編輯使用者',
    update_users: '編輯使用者',
    confirm_remove: '確定刪除以下人員：',
    [`update_user_error_message_${ EMAIL_FORMAT_INVALID }`]: '信箱格式錯誤',
    [`update_user_error_message_${ STATUS_INVALID }`]: '狀態錯誤',
    [`update_user_error_message_${ ROLE_ID_INVALID }`]: '角色錯誤',
    [`update_user_error_message_${ ACCOUNT_ALREADY_EXISTS }`]: '帳號已存在',
};

export default admin;
