import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Map } from 'immutable';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { fetchNewToken, fetchPermission, logout, deleteCookie } from 'actions';
import Blocker from 'components/Blocker';
import RingSpinner from 'components/Spinner/Ring';

// Popup
import PopupScooterDetail from './Popup/PopupScooterDetail';
import PopupNavigation from './Popup/PopupNavigation';
import ErrorMessage from './Popup/ErrorMessage';
import PleaseLoginDialog from './Popup/PleaseLoginDialog';

// background runners
import RefreshPermission from './Background/RefreshPermission';

import './app.scss';

class App extends Component {
    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.element,
        isLoggedIn: PropTypes.bool.isRequired,
        profile: PropTypes.shape({}),
    };

    static defaultProps = {
        title: '',
        children: <span />,
        profile: Map(),
    };

    constructor(props) {
        super(props);
        this.state = {
            waitForRetrieveToken: true,
        };
    }

    componentDidMount() {
        const { isLoggedIn } = this.props;

        // already logged in
        if (isLoggedIn) {
            this.handleRetrieveToken();
        }
        else {
            this.setState({
                waitForRetrieveToken: false,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { isLoggedIn } = this.props;

        // be logging in
        if (!prevProps.isLoggedIn && isLoggedIn) {
            this.setState({
                waitForRetrieveToken: true,
            });
            this.handleRetrieveToken();
        }
    }

    handleRetrieveToken() {
        const { dispatch } = this.props;
        dispatch(fetchNewToken()).then(({ data }) => {
            if (!data) {
                return ;
            }

            dispatch(fetchPermission(data.id_token))
                .then((data) => {
                    if (data) {
                        this.setState({
                            waitForRetrieveToken: false,
                        });
                    }
                });
        }).catch(() => {
            this.setState({
                waitForRetrieveToken: false,
            }, () => {
                dispatch(logout());
                dispatch(deleteCookie());
            });
        });
    }

    render() {
        const { children, title, history } = this.props;
        const { waitForRetrieveToken } = this.state;
        const meta = {
            title: I18n.t('Operation Management') + ' / ' + (title && I18n.t(title)),
        };

        return (

            waitForRetrieveToken ?
                (
                    <Blocker>
                        <RingSpinner />
                    </Blocker>
                ) : (
                    <div className="app">
                        <Helmet>
                            <title>{ meta.title }</title>
                        </Helmet>
                        <Sidebar history={ history } />
                        <main className="main-content">
                            <Header />

                            <section className="app-container">
                                { children }
                            </section>
                        </main>
                        <div className="popup">
                            <PleaseLoginDialog />
                            <PopupScooterDetail />
                            <PopupNavigation />
                            <ErrorMessage />
                        </div>

                        <RefreshPermission />
                    </div>
                )
        );
    }
}

export default withRouter(connect(state => ({
    locale: state.i18n.locale,
    title: state.general.get('title'),
    isLoggedIn: state.account.get('isLoggedIn'),
    profile: state.account.get('profile'),
}))(App));
