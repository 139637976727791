import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import UserRoleFilter from 'components/Filter/UserRoleFilter';
import ChoiceGroupField from 'components/ChoiceGroup/ChoiceGroupField';
import AuthFeature from 'components/AuthFeature';
import { AUTH_VIEW_FLEET_USER_ROLE } from 'constants/permission';

export const FILTER_ROLE = 'role_id';
export const FILTER_FRANCHISEE = 'franchisee_id';
export const FILTER_ACCOUNT = 'account';
export const FILTER_NAME = 'name';

class FilterBar extends React.Component {
    static propTypes = {
        defaultSearchType: PropTypes.string.isRequired,
        selected: PropTypes.shape({}).isRequired,
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearchValueChange: PropTypes.func.isRequired,
        searchValue: PropTypes.string,
        onChangeSearchType: PropTypes.func.isRequired,
    };

    static defaultProps = {
        searchValue: undefined,
    }

    handleChange = (type) => value => {
        const { onChange } = this.props;
        onChange(type, value.inputSelect);
    }

    render() {
        const {
            selected,
            defaultSearchType,
            searchValue,
            onChangeSearchType,
            onSearchValueChange,
            onSubmit
        } = this.props;
        const options = [
            { id: FILTER_ACCOUNT, name: I18n.t('admin.account'), value: FILTER_ACCOUNT },
            { id: FILTER_NAME, name: I18n.t('admin.name'), value: FILTER_NAME }
        ];

        return (
            <FlexGroup className="filters" start>
                <ChoiceGroupField
                    renderTitle={ () => <Translate value="search" /> }
                    defaultChecked={ defaultSearchType }
                    options={ options }
                    value={ searchValue }
                    onChoiceChange={ onChangeSearchType }
                    onSubmit={ onSubmit }
                    onChange={ onSearchValueChange }
                />
                <AuthFeature requiredList={ [ AUTH_VIEW_FLEET_USER_ROLE ] }>
                    <UserRoleFilter
                        defaultSelected={ selected[FILTER_ROLE] }
                        onChange={ this.handleChange(FILTER_ROLE) }
                    />
                </AuthFeature>
            </FlexGroup>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(FilterBar);
