import {
    TRUNK_CLOSED,
    TRUNK_OPEN,
    TRUNK_LOCKED,

    SCOOTER_STATE_UNLOCK,
    SCOOTER_STATE_ENGINE_ON,
    SCOOTER_STATE_LOCK,
    SCOOTER_STATE_SHUTDOWN,

    SCOOTER_OFFLINE,
    SCOOTER_ONLINE,

    FORCE_RETURN_ERROR_CODE_3000,
    FORCE_RETURN_ERROR_CODE_3006,
    FORCE_RETURN_ERROR_CODE_3007,
    FORCE_RETURN_ERROR_CODE_3008,
    FORCE_RETURN_ERROR_CODE_3009,
    FORCE_RETURN_ERROR_CODE_3010,

} from 'constants/scooter';

const trunkState = {
    [TRUNK_CLOSED]: '關閉',
    [TRUNK_OPEN]: '開啟',
    [TRUNK_LOCKED]: '上鎖',
};
const scooterState = {
    [SCOOTER_STATE_UNLOCK]: '解鎖',
    [SCOOTER_STATE_ENGINE_ON]: '馬達啟動',
    [SCOOTER_STATE_LOCK]: '上鎖',
    [SCOOTER_STATE_SHUTDOWN]: '休眠',
};
const availabilityState = {
    [SCOOTER_OFFLINE]: '下線',
    [SCOOTER_ONLINE]: '上線',
};

const scooter = {
    'details': '詳細資料',
    'remote_control': '遠端控制',
    'lock': '上鎖',
    'unlock': '解鎖',
    'open_trunk': '開車廂',
    'find': '尋車',
    'plate': '車牌號碼',
    'battery_with_unit': '電量 (%)',
    'battery': '電量',
    'tracker_soc_with_unit': 'GTU (%)',
    'tracker_soc': 'GTU',
    'remaining_mileage_with_unit': '可行駛里程 (km)',
    'remaining_mileage': '可行駛里程',
    'csq': 'CSQ',
    'satellites': '衛星',
    'model': '型號',
    'activity': '上線狀況',
    'rental_state': '狀態',
    'last_rental_end_time': '上次租賃時間',
    'idle': '閒置 (hrs)',
    'total_mileage': '總里程',
    'total_mileage_with_unit': '總里程 (km)',
    'scooter_state': '車輛狀況',
    'location': '位置',
    'trunk_state': '車廂',
    'last_connection_date': '離線',
    'lct': '離線 (hrs)',
    'last_udpated': '更新時間',
    'service': '服務',
    'activation': {
        'on': '上線',
        'off': '下線',
    },
    'trunk_state_map': trunkState,
    'scooter_state_map': scooterState,
    'availability_state_map': availabilityState,
    'batch_update_service_state_error_message': '哦喔! 車輛(%{plates}) 服務狀態設定失敗，請再試一次',
    'force_return': '強制還車',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3000}_error_message`]: '租賃不存在',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3006}_error_message`]:
        '電池狀態異常',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3007}_error_message`]: '車廂未關閉 (車廂開啟或未鎖)',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3008}_error_message`]: '馬達啟動',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3009}_error_message`]: '車輛未鎖',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3010}_error_message`]: 'GTU 連線錯誤',
    'force_return_error_message': '強制還車錯誤 (%{code})',
    'number_constraint_of_scooter': '選擇的車輛請勿多於 200 台',
    'excellent': '訊號強',
    'good': '訊號良好',
    'ok': '訊號尚可',
    'marginal': '訊號微弱',
    'poor': '訊號差',
    'no_signal_detectable': '無信號',
    'satellitesHigh': '準確度高',
    'satellitesLow': '準確度低',
    'corporate': '企業',
};

export default scooter;
