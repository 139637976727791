import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { getBounds } from 'helpers/gmap-utils';
import GoogleMap, { VIP_SELECT, DEFAULT_LOCATION } from 'components/Map';
import FlexGroup from 'components/FlexGroup';
import NewTicket from 'components/NewTicket';
import { Refresh, TotalCount } from 'components/Pagination';
import { Translate, I18n } from 'react-redux-i18n';
import ActionPanel from 'components/ActionPanel';
import TotalSelection from 'components/TotalSelection';
import buildActualPath from 'helpers/build-actual-path';
import { Button } from 'reactstrap';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
    TOP_LEFT,
} from 'components/Map/Toolbox';
import { selectScooter, toggleErrorDialog } from 'actions';
import {
    AUTH_VIEW_SCOOTER_DETAIL,
} from 'constants/permission';
import permissionHandler from 'helpers/permission-handler';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';
import {
    SCOOTER_PROFILE,
} from 'constants/routes';

const defaultToolbox = [CENTER_TO_CURRENT_LOCATION, VMS];

class MapView extends React.PureComponent {
    static propTypes = {
        list: ImmutablePropTypes.listOf(PropTypes.shape({
            scooter_id: PropTypes.string,
            model_code: PropTypes.number,
            lng: PropTypes.number,
            lat: PropTypes.number,
            service_id: PropTypes.string,
            service_type: PropTypes.number,
        })),
        __responseTime: PropTypes.shape({}),
        onFetch: PropTypes.func.isRequired,
        resetBounds: PropTypes.number.isRequired,
        selectedIdList: ImmutablePropTypes.list.isRequired,
        permission: ImmutablePropTypes.map,
    };

    static defaultProps = {
        list: [],
        __responseTime: undefined,
        permission: null,
    };

    constructor(props) {
        super(props);
        this.service_ids = [];
        this.vipLayer = VIP_SELECT;
        this.state = {
            hiddenActionPanel: true,
            createTicket: false,
            isConditional: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { dispatch, resetBounds } = this.props;
        const prevResetBounds = prevProps.resetBounds;
        if (prevResetBounds !== resetBounds) {
            dispatch(selectScooter([]));
            this.mapFitBounds();
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(selectScooter([]));
    }

    fetchData = () => {
        const { onFetch } = this.props;

        return onFetch();
    }

    setCenterWithoutScooters = () => {
        const { permission } = this.props;
        const { corporate_info } = permission.toJS();
        const { center_lat: corpLat, center_lng: corpLng } = corporate_info;
        let lat = DEFAULT_LOCATION.lat;
        let lng = DEFAULT_LOCATION.lng;
        if (corpLat && corpLng) {
            lat = corpLat;
            lng = corpLng;
        }

        this.map.setCenter({
            lat,
            lng,
        });
        this.map.setZoom(15);
    };

    mapFitBounds = () => {
        const list = this.getScooterList();

        if (this.map) {
            if (list.length === 1) {
                const { lat, lng } = list[0];
                if ((lat && lng) || (lat === 0 && lng) || (lat && lng === 0)) {
                    this.map.setCenter({
                        lat,
                        lng,
                    });
                    this.map.setZoom(15);
                }
                else {
                    this.setCenterWithoutScooters();
                }
            }
            else if (list.length > 1) {
                const initBounds = new this.maps.LatLngBounds();
                const bounds = getBounds(list, this.maps);
                if (isEqual(initBounds, bounds)) {
                    this.setCenterWithoutScooters();
                }
                else {
                    this.map.fitBounds(bounds);
                }
            }
        }
    }

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
        this.mapFitBounds();
    }

    afterNewTicket = (hasToggle = true) => {
        const { dispatch } = this.props;

        dispatch(selectScooter([]));
        hasToggle && this.toggleNewTicket()();
    }

    handleScooterSelected = (newSelectedIdList, { selected }) => {
        const { dispatch, selectedIdList } = this.props;
        const scooterSet = new Set(selectedIdList.toJS());
        newSelectedIdList.forEach(id => {
            if (selected) {
                scooterSet.add(id);
            }
            else {
                scooterSet.delete(id);
            }
        });

        dispatch(selectScooter(Array.from(scooterSet)));
    }

    getScooterList = () => {
        const { list } = this.props;
        let realList = list.toJS();

        realList = realList.map(({ scooter_id, model_code, lat, lng, service_id, service_type, visible }) => ({
            id: scooter_id,
            model_code,
            lat,
            lng,
            service_id,
            service_type,
            visible,
        }));

        return realList;
    }

    clearAllSelected = () => {
        const { dispatch } = this.props;

        dispatch(selectScooter([]));
    }

    toggleNewTicket = (constraint = false, isConditional = false) => () => {
        const { dispatch, selectedIdList } = this.props;
        if (constraint && selectedIdList.size > 200) {
            dispatch(toggleErrorDialog(I18n.t('scooter.number_constraint_of_scooter')));
            return;
        }

        const { createTicket } = this.state;
        this.setState({
            createTicket: !createTicket,
            isConditional,
        });
    }

    toggleClose = (constraint = false) => {
        const { dispatch, selectedIdList } = this.props;
        if (constraint && selectedIdList.size > 200) {
            dispatch(toggleErrorDialog(I18n.t('scooter.number_constraint_of_scooter')));
            return;
        }

        const { hiddenActionPanel } = this.state;
        this.setState({
            hiddenActionPanel: !hiddenActionPanel,
        });
    }

    afterDidAction = () => {
        this.clearAllSelected();
        this.toggleClose();
    }

    render() {
        const { list, selectedIdList, __responseTime } = this.props;
        const { hiddenActionPanel, createTicket, isConditional } = this.state;
        const hasScooterSelected = selectedIdList.size > 0;
        const toolboxList = getAuthedToolBoxList([...defaultToolbox]);
        return (
            <React.Fragment>
                {
                    !hiddenActionPanel ?
                        (
                            <ActionPanel
                                scooterIdList={ selectedIdList.toJS() }
                                onDidAction={ this.afterDidAction }
                                onClose={ this.toggleClose }
                            />
                        ) :
                        null
                }
                <FlexGroup>
                    <FlexGroup start gap>
                        <Button
                            color="primary"
                            type="button"
                            disabled={ !hasScooterSelected }
                            onClick={ () => this.toggleClose(true) }
                        >
                            <Translate value="do_action" />
                        </Button>
                        <Button
                            color="secondary"
                            onClick={ this.clearAllSelected }
                            disabled={ selectedIdList.size === 0 }
                        >
                            <Translate value="clear_all" />
                        </Button>
                        <TotalCount count={ list.size } />
                        {
                            selectedIdList.size === 1 &&
                            permissionHandler({ requiredList: [AUTH_VIEW_SCOOTER_DETAIL] }) ?
                                (
                                    <NavLink to={ buildActualPath(SCOOTER_PROFILE,
                                        { scooterId: (selectedIdList.toJS())[0] }) }
                                    >
                                        <Translate value="total_selection" />
                                        <span>{ `: ${ selectedIdList.size }` }</span>
                                    </NavLink>
                                ) : (
                                    <TotalSelection count={ selectedIdList.size } />
                                )
                        }
                    </FlexGroup>
                    <FlexGroup end gap>
                        <Refresh time={ __responseTime } onClick={ this.fetchData } disabled={ hasScooterSelected } />
                    </FlexGroup>
                </FlexGroup>
                <NewTicket
                    isConditional={ isConditional }
                    show={ createTicket }
                    scooterIdList={ selectedIdList.toJS() }
                    onCreated={ this.afterNewTicket }
                    onCancelled={ this.toggleNewTicket() }
                />
                <GoogleMap
                    className="map-view"
                    onInternalAPI={ this.handleGoogleApi }
                    toolbox={ toolboxList }
                    onScooterSelected={ this.handleScooterSelected }
                    list={ this.getScooterList() }
                    drawingManager
                    currentLocation
                    position={ TOP_LEFT }
                    vipLayer={ this.vipLayer }
                />
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    list: state.scooter.getIn(['allList', 'list']),
    __responseTime: state.scooter.getIn(['allList', '__responseTime']),
    selectedIdList: state.map.get('selectedIdList'),
    permission: state.account.get('permission'),
}))(MapView);
