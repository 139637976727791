/**
 * Reducer - Ticket
 */
import { Map, List, fromJS } from 'immutable';
import {
    FETCH_TICKET_LIST,
    FETCH_ONE_TICKET,
    CREATE_TICKETS,
    UPDATE_ONE_TICKET,
    CLEAN_ONE_TICKET,
    UPDATE_MANY_TICKET,
    ASSIGN_SWAP_F_TICKET,
    FETCH_ASSIGNMENT,
    INIT_ASSIGNMENTS,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: Map({
        data_list: List([]),
    }),
    oneTicket: Map(),
    createResult: Map(),
    batchUpdateResult: Map(),
    assignments: Map({
        data_list: List([]),
    }),
    myTicketFlowList: List([]),
    defaultTicketFlowList: List([]),
});

function orderTicketStatusListFunc(statusObjA, statusObjB) {
    return statusObjA.status_id - statusObjB.status_id;
}

const actionsMap = {
    [FETCH_TICKET_LIST]: (state, action) => {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            list: Map({
                page_index,
                page_count,
                total,
                data_list: List(data_list),
                __responseTime,
            }),
        });
    },

    [FETCH_ONE_TICKET]: (state, action) => {
        const { description, location_lat, location_lng, ...others } = action.data;
        others.status_editable_list.sort(orderTicketStatusListFunc);

        return state.merge({
            oneTicket: Map({
                description: description ?? '',
                location_lat: location_lat ?? 0,
                location_lng: location_lng ?? 0,
                ...others
            }),
        });
    },

    [UPDATE_ONE_TICKET]: (state, action) => {
        const { description, ...others } = action.data;
        others.status_editable_list.sort(orderTicketStatusListFunc);

        return state.merge({
            oneTicket: Map({
                description: description ?? '',
                ...others
            }),
        });
    },

    [CLEAN_ONE_TICKET]: (state) => {
        return state.merge({
            oneTicket: Map()
        });
    },

    [UPDATE_MANY_TICKET]: (state, action) => {
        // have to update list manually
        return state.merge({
            batchUpdateResult: Map(action.data),
        });
    },

    [CREATE_TICKETS]: (state, action) => {
        return state.merge({
            createResult: Map(action.data),
        });
    },

    [ASSIGN_SWAP_F_TICKET]: state => {
        return state;
    },

    [FETCH_ASSIGNMENT]: (state, action) => {
        const { data } = action;

        return state.merge({
            assignments: fromJS(data),
        });
    },
    [INIT_ASSIGNMENTS]: (state) => {
        const __responseTime = state.getIn(['assignments', '__responseTime']);

        return state.merge({
            assignments: fromJS({
                data_list: [],
                __responseTime,
            }),
        });
    },
};

export default creater(actionsMap, initialState);
