import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { I18n } from 'react-redux-i18n';
import Smart from 'components/Smart';
import ScooterProfile from './Profile';
import NavigationBar from 'components/NavigationBar';
import {
    clearSingleScooter,
    updateDocumentTitle,
    clearVMS,
} from 'actions';
import { SCOOTER_LIST } from 'constants/routes';

class ScooterDetail extends Component {
    static propTypes = {
        singleScooter: ImmutablePropTypes.map,
        params: PropTypes.shape({}).isRequired,
        onFetch: PropTypes.func.isRequired,
        customAction: PropTypes.func,
    };

    static defaultProps = {
        singleScooter: new Map(),
        customAction: () => {},
    }

    constructor(props) {
        super(props);

        this.queryPayload = {
            scooter_id: undefined,
            page: 1,
            size: 20,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle(I18n.t(`routes.${[SCOOTER_LIST]}`)));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
        dispatch(clearVMS());
    }

    fetchData = () => {
        const { params, onFetch } = this.props;
        const scooterId = params.scooterId;

        return onFetch(scooterId);
    }

    handleHistoryPageSelect = page => {
        this.queryPayload.page = page;

        this.fetchScooterRentalHistory();
    }

    render() {
        const { singleScooter, customAction } = this.props;

        return (
            <div className="detail-list">
                <div
                    className="form-container scooter-details"
                >
                    <Smart
                        className="scooter-profile-page"
                        fetch={ () => {} }
                        pauseRefresh
                        seamless
                    >
                        <NavigationBar
                            sticky
                            title={ singleScooter.get('plate') }
                            className="navigation-bar"
                            customAction={ customAction }
                        />
                        <ScooterProfile item={ singleScooter.toJS() } />
                    </Smart>
                </div>
            </div>
        );
    };
}

export default connect((state, ownProps) => ({
    singleScooter: state.scooter.get('singleScooter')[ownProps.params.scooterId],
    i18n: state.i18n,
}))(ScooterDetail);
