/**
 * get current bounds by locations [{ lat, lng }, ...]
 */
export const getBounds = (locations, maps) => {
    const bounds = new maps.LatLngBounds();

    locations.filter(({ lat, lng }) => lat && lng).forEach(({ lat, lng }) => {
        bounds.extend(new maps.LatLng({ lat, lng }));
    });

    return bounds;
};

export const checkDistance = (p1, p2, distance) => {
    const rad = x => x * Math.PI / 180;

    // Earth’s mean radius in meter
    const R = 6378137;
    const dLat = rad(p2.lat - p1.lat);
    const dLong = rad(p2.lng - p1.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    // returns the distance in 5km
    return d < distance;
};
